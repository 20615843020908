import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RouletteInGame } from "./screens/RouletteInGame";
import { UpgraderWaiting } from "./screens/UpgraderWaiting";
import { OpenCase } from "./screens/OpenCase";
import { OpenCases } from "./screens/OpenCases";
import { Withdrawal } from "./screens/Withdrawal";
import { Transactions } from "./screens/Transactions";
import { GameHistory } from "./screens/GameHistory";
import { Settings } from "./screens/Settings";
import { Footer } from "./screens/Footer";
import { Bets } from "./screens/Bets";
import { TermsOfService } from "./screens/TermsOfService";
import { PrivacyPolicy } from "./screens/PrivacyPolicy";
import { Faq } from "./screens/Faq";
import { HomePage } from "./screens/HomePage";
import {Header} from "./screens/Header";

const router = createBrowserRouter([
  {
    path: "/*",
    element: <HomePage />,
  },
  {
    path: "/roulette-u40in-gameu41",
    element: <RouletteInGame />,
  },
  {
    path: "/upgrader-u40waitingu41-1",
    element: <UpgraderWaiting />,
  },
  {
    path: "/open-case",
    element: <OpenCase />,
  },
  {
    path: "/open-cases",
    element: <OpenCases />,
  },
  {
    path: "/withdrawal",
    element: <Withdrawal />,
  },
  {
    path: "/transactions",
    element: <Transactions />,
  },
  {
    path: "/game-history",
    element: <GameHistory />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/footer-1",
    element: <Footer />,
  },
  {
    path: "/bets",
    element: <Bets />,
  },
  {
    path: "/terms-of-service",
    element: <TermsOfService />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/faq",
    element: <Faq />,
  },
  {
    path: "/home-page-1",
    element: <HomePage />,
  },
]);

export const App = () => {
  return (
    <>
    <Header/>
  <RouterProvider router={router} />
    <Footer />
  </>
)
};
