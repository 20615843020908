import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import { VuesaxBoldWallet } from "../../components/VuesaxBoldWallet";
import {LiveChat} from "../LiveChat/LiveChat";
import "./style.css";

export const OpenCases = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="open-cases">
      <div
        className="div-7"
        style={{
          height: screenWidth < 900 ? "996px" : screenWidth >= 900 ? "1080px" : undefined,
          overflow: screenWidth < 900 ? "hidden" : undefined,
          position: screenWidth >= 900 ? "relative" : undefined,
          width: screenWidth < 900 ? "375px" : screenWidth >= 900 ? "100% " : undefined,
        }}
      >
        {screenWidth < 900 && (
          <div className="overlap-29">
            <img className="vector-12" alt="Vector" src="/img/vector-76.png" />
            <div className="ellipse-47" />
            <div className="rectangle-18" />
            <div className="rectangle-19" />
            <div className="text-wrapper-136">Open cases</div>
            <div className="frame-218">
              <div className="frame-219">
                <div className="frame-220">
                  <div className="text-wrapper-137">All cases</div>
                  <div className="frame-221">
                    <div className="chevron-down-wrapper">
                      <ChevronDownWrapper />
                    </div>
                    <div className="frame-222">
                      <div className="text-wrapper-138">View all</div>
                    </div>
                    <div className="chevron-down-instance-wrapper">
                      <ChevronDownWrapper />
                    </div>
                  </div>
                </div>
                <div className="frame-219">
                  <div className="frame-220">
                    <div className="frame-223">
                      <img className="image-13" alt="Image" src="/img/image-9-5.png" />
                      <div className="frame-224">
                        <div className="text-wrapper-139">POPULAR</div>
                      </div>
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <div className="frame-227">
                          <img className="storage-18" alt="Storage" src="/img/storage-5634434-1-261.png" />
                          <div className="text-wrapper-142">54,5</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-223">
                      <img className="image-14" alt="Image" src="/img/image-9.png" />
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <div className="frame-227">
                          <img className="storage-18" alt="Storage" src="/img/storage-5634434-1-261.png" />
                          <div className="text-wrapper-142">54,5</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-220">
                    <div className="frame-223">
                      <img className="image-13" alt="Image" src="/img/image-9-1.png" />
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <div className="frame-227">
                          <img className="storage-18" alt="Storage" src="/img/storage-5634434-1-261.png" />
                          <div className="text-wrapper-142">54,5</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-223">
                      <img className="image-13" alt="Image" src="/img/image-9-3.png" />
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <div className="frame-227">
                          <img className="storage-18" alt="Storage" src="/img/storage-5634434-1-261.png" />
                          <div className="text-wrapper-142">54,5</div>
                        </div>
                      </div>
                      <div className="frame-228">
                        <div className="text-wrapper-139">NEW</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-219">
                <div className="frame-220">
                  <div className="text-wrapper-137">Other cases</div>
                  <div className="frame-221">
                    <div className="frame-229">
                      <ChevronDownWrapper />
                    </div>
                    <div className="frame-222">
                      <div className="text-wrapper-138">View all</div>
                    </div>
                    <div className="chevron-down-instance-wrapper">
                      <ChevronDownWrapper />
                    </div>
                  </div>
                </div>
                <div className="frame-219">
                  <div className="frame-220">
                    <div className="frame-223">
                      <img className="image-14" alt="Image" src="/img/image-9.png" />
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <div className="frame-230">
                          <div className="text-wrapper-143">$54,5</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-223">
                      <img className="image-13" alt="Image" src="/img/image-9-5.png" />
                      <div className="frame-224">
                        <div className="text-wrapper-139">POPULAR</div>
                      </div>
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <div className="frame-230">
                          <div className="text-wrapper-143">$54,5</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-220">
                    <div className="frame-223">
                      <img className="image-13" alt="Image" src="/img/image-9-1.png" />
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <div className="frame-230">
                          <div className="text-wrapper-143">$54,5</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-223">
                      <img className="image-13" alt="Image" src="/img/image-9-3.png" />
                      <div className="frame-225">
                        <div className="frame-226">
                          <div className="text-wrapper-140">Premium Fire</div>
                          <div className="text-wrapper-141">Popular cases</div>
                        </div>
                        <div className="frame-230">
                          <div className="text-wrapper-143">$54,5</div>
                        </div>
                      </div>
                      <div className="frame-228">
                        <div className="text-wrapper-139">NEW</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-231">
              <div className="overlap-group-27">
                <div className="element-indicator-2">
                  <div className="line-10" />
                </div>
                <div className="frame-232">
                  <div className="frame-233">
                    <img className="img-9" alt="Home" src="/img/home-06-2.png" />
                    <div className="text-wrapper-144">Home</div>
                  </div>
                  <div className="frame-234">
                    <img className="img-9" alt="Gameboy" src="/img/gameboy-2.png" />
                    <div className="text-wrapper-145">Games</div>
                  </div>
                  <div className="frame-234">
                    <img className="img-9" alt="Chatting" src="/img/chatting-01.png" />
                    <div className="text-wrapper-145">Chat</div>
                  </div>
                  <div className="frame-234">
                    <div className="img-9">
                      <div className="frame-235">
                        <img className="rectangle-20" alt="Rectangle" src="/img/rectangle-1-1.png" />
                      </div>
                    </div>
                    <div className="text-wrapper-145">Settings</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-236">
              <div className="element-system-bar-3">
                <div className="group-13">
                  <div className="battery-3">
                    <div className="overlap-group-28">
                      <div className="capacity-3" />
                    </div>
                    <img className="cap-3" alt="Cap" src="/img/cap-1.png" />
                  </div>
                  <img className="wifi-3" alt="Wifi" src="/img/wifi-1.png" />
                  <img
                    className="cellular-connection-3"
                    alt="Cellular connection"
                    src="/img/cellular-connection-3.png"
                  />
                </div>
                <div className="starus-2">
                  <p className="time-3">
                    <span className="text-wrapper-146">9:4</span>
                    <span className="text-wrapper-147">1</span>
                  </p>
                </div>
                <div className="floating-island-3" />
              </div>
              <div className="frame-237">
                <img className="logo-6" alt="Logo" src="/img/logo-36.png" />
                <div className="frame-238">
                  <div className="frame-221">
                    <img className="img-9" alt="Storage" src="/img/storage-5634434-1-2.png" />
                    <div className="text-wrapper-148">789</div>
                  </div>
                  <div className="frame-239">
                    <div className="frame-240">
                      <div className="frame-241">
                        <div className="text-wrapper-149">Deposit</div>
                      </div>
                    </div>
                    <div className="frame-242">
                      <VuesaxBoldWallet
                        className="vuesax-bold-wallet-5"
                        vuesaxBoldWallet="/img/vuesax-bold-wallet-2-16.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {screenWidth >= 900 && (
          <>
            <div className="overlap-30 scrollable-area">
              <img className="vector-13" alt="Vector" src="./Img/vector_background.svg" />
              <div className="ellipse-49" />
              <div className="frame-254">
                <div className="frame-238">
                  <div className="text-wrapper-156">Open cases</div>
                </div>
                <div className="frame-255">
                  <div className="frame-256">
                    <div className="frame-220">
                      <div className="text-wrapper-157">All cases</div>
                      <div className="frame-221">
                        <div className="frame-257">
                        <img className="img-arrow" alt="left-arrow" src="./Img/right-arrow.svg"/>
                        </div>
                        <div className="frame-258">
                          <div className="text-wrapper-158">View all</div>
                        </div>
                        <div className="frame-259">
                          <img className="img-arrow" alt="left-arrow" src="./Img/right-arrow.svg"/>
                        </div>
                      </div>
                    </div>
                    <div className="frame-260">
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-2.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                        <div className="frame-265">
                          <div className="text-wrapper-153">POPULAR</div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-4.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-3.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-261">
                      <img className="image-15" alt="Image" src="./Img/open-cases-4.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-261">
                      <img className="image-15" alt="Image" src="./Img/open-cases-5.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-162">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="frame-256">
                    <div className="frame-220">
                      <div className="text-wrapper-157">Other cases</div>
                      <div className="frame-221">
                        <div className="frame-257">
                        <img className="img-arrow" alt="left-arrow" src="./Img/right-arrow.svg"/>
                        </div>
                        <div className="frame-258">
                          <div className="text-wrapper-158">View all</div>
                        </div>
                        <div className="frame-259">
                        <img className="img-arrow" alt="left-arrow" src="./Img/right-arrow.svg"/>
                        </div>
                      </div>
                    </div>
                    <div className="frame-269">
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-2.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-3.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-4.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-3.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                        <div className="frame-270">
                          <div className="text-wrapper-153">NEW</div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-256">
                    <div className="frame-220">
                      <div className="text-wrapper-157">Other cases</div>
                      <div className="frame-221">
                        <div className="frame-257">
                        <img className="img-arrow" alt="left-arrow" src="./Img/right-arrow.svg"/>
                        </div>
                        <div className="frame-258">
                          <div className="text-wrapper-158">View all</div>
                        </div>
                        <div className="frame-259">
                        <img className="img-arrow" alt="left-arrow" src="./Img/right-arrow.svg"/>
                        </div>
                      </div>
                    </div>
                    <div className="frame-269">
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-2.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-4.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-3.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-4.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases-3.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                        <div className="frame-270">
                          <div className="text-wrapper-153">NEW</div>
                        </div>
                      </div>
                      <div className="frame-261">
                        <img className="image-15" alt="Image" src="./Img/open-cases.png" />
                        <div className="frame-262">
                          <div className="frame-263">
                            <div className="text-wrapper-159">Premium Fire</div>
                            <div className="text-wrapper-160">Popular cases</div>
                          </div>
                          <div className="frame-264">
                            <img className="img-11" alt="Storage" src="./Img/storage-open-cases.svg" />
                            <div className="text-wrapper-161">54,5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rectangle-23" />
              {/* <div className="frame-271">
                <div className="frame-272">
                  <div className="frame-273">
                    <img className="img-11" alt="Vuesax bold setting" src="/img/vuesax-bold-setting-2-8.png" />
                    <div className="text-wrapper-164">Settings</div>
                  </div>
                </div>
                <div className="frame-274">
                  <div className="frame-239">
                    <div className="img-11">
                      <div className="gamepad" />
                    </div>
                    <div className="text-wrapper-165">Game history</div>
                  </div>
                </div>
                <div className="frame-272">
                  <div className="frame-273">
                    <img className="img-11" alt="Vuesax bold receipt" src="/img/vuesax-bold-receipt-2.png" />
                    <div className="text-wrapper-164">Transactions</div>
                  </div>
                </div>
                <div className="frame-272">
                  <div className="frame-273">
                    <img className="img-11" alt="Vuesax linear logout" src="/img/vuesax-linear-logout-3.png" />
                    <div className="text-wrapper-166">Log Out</div>
                  </div>
                </div>
              </div> */}
            </div>
            <LiveChat />
          </>
        )}
      </div>
    </div>
  );
};
