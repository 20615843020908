/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { ChevronDown } from "../../icons/ChevronDown";
import "./style.css";

export const ChevronDownWrapper = () => {
  return <ChevronDown className="chevron-down" />;
};
