import React from "react";
import "./style.css";
import { Gb } from "../../components/Gb";
import { useWindowWidth } from "../../breakpoints";
import { SelectBox } from "../../components/SelectBox";
import { Img } from "../../components/Img";


const dropDownOptions = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" }
];
export const LiveChat = () => {
  const screenWidth = useWindowWidth();

  return (
    <>
      <div className="LiveChat">
        <div className="chat-12">
          <div className="chat-header-12">
            <div className="overlap-group-161">
              <div className="ellipse-126" />
              <div className="frame-919">
                <img className="frame-920" alt="Frame" src="./Img/frame-1.png" />
                <div className="text-wrapper-546">Live rain </div><div className="text-wrapper-second">Now</div>
              </div>
            </div>
            <div className="frame-921">

             
            </div>
          </div>
          <div className="overlap-97">
            <div className="frame-923">


            </div>
            <div className="frame-930">
              <div className="frame-931">
                <input className="text-wrapper-555" type="text"
                  placeholder="Your message..."
                />

                <img className="smile-12" alt="Smile" src="./Img/smile.svg" />
                <button className="frame-932">
                  <img className="frame-933" alt="Frame" src="./Img/frame-37.png" />
                </button>

              </div>

            </div>             
             <div className="frame-934">
             <div className="ellipse-131" />
             <div className="frame-922">
                <SelectBox
                  shape="round"
                  indicator={<Img src="images/img_arrowdown.svg" alt="" className="h-[16px] w-[14px]" />}
                  getOptionLabel={(e) => (
                    <>
                      <div className="flex items-center">
                        {/* <Img src="images/img_grid.png" alt="grid" className="h-[16px] w-[20px]" /> */}
                        <a>{e.label}</a>
                      </div>
                    </>
                  )}
                  name="grid"
                  placeholder={`ENG`}
                  options={dropDownOptions}
                  className="w-[38%] gap-1.5 font-semibold"
                />
                <div className="frame-910">
                <div className="ellipse-127" />
                <div className="text-wrapper-547">0</div>
              </div>
              </div>
              
            </div>


          </div>

        </div>
      </div >
    </>
  );
};