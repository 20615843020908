import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { SelectBox} from "../../components/SelectBox";
import {Img} from "../../components/Img";
import "./style.css";

const dropDownOptions = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" }
];

export const Footer = () => {
  const screenWidth = useWindowWidth();

  return (
    <div
      className="footer"
      style={{
        minWidth: screenWidth < 900 ? "351px" : screenWidth >= 900? "100%" : undefined,
        padding: screenWidth < 900 ? "20px" : screenWidth >= 900 ? "40px 96px" : undefined,
      }}
    >
      <div className="content-footer">
        {screenWidth < 900 && (
          <div className="gap-language">
            <div className="frame-682">
              <img className="logo-footer" alt="Rust Oasis" src="Img/logo1.png"/>
              <div className="frame-683">
               <SelectBox
                    shape="round"
                    indicator={<Img src="images/img_arrowdown.svg" alt="" className="h-[16px] w-[14px]" />}
                    getOptionLabel={(e) => (
                      <>
                        <div className="flex items-center">
                          <Img src="images/img_grid.png" alt="Grid" className="h-[16px] w-[20px]" />
                          <span>{e.label}</span>
                        </div>
                      </>
                    )}
                    name="grid"
                    placeholder={`ENG`}
                    options={dropDownOptions}
                    className="w-[38%] gap-1.5 font-semibold"
                  />
                  </div>
            </div>
          </div>
        )}

        <div
          className="frame-684"
          style={{
            gap: screenWidth < 900 ? "24px" : screenWidth >= 900 ? "28px" : undefined,
          }}
        >
          {screenWidth < 900 && (
            <>
              <div className="frame-685">
                <div className="frame-686">
                  <div className="content-18">
                    <div className="text-wrapper-393">18+</div>
                  </div>
                  <p className="by-accessing-this">
                    <span className="text-wrapper-394">By accessing this site, you confirm that you are </span>
                    <span className="text-wrapper-395">over 18 years old</span>
                  </p>
                </div>
              </div>
              <div className="div-18">
                <div className="gap-list">
                  <ul className="frame-689">
                    <li className="text-wrapper-396">Games</li>
                    <li className="text-wrapper-397">Roulette</li >
                    <li  className="text-wrapper-397">Case Battle</li >
                    <li  className="text-wrapper-397">Cases</li >
                    <li  className="text-wrapper-397">Upgrader</li >
                  </ul>
                  
                  <ul className="frame-689">
                    <li className="text-wrapper-396">Information</li>
                    <li className="text-wrapper-397" >FAQ</li>
                    <li className="text-wrapper-397">Terms of Use</li>
                    <li className="text-wrapper-397">Privacy Policy</li>
                    <li className="text-wrapper-397">About us</li>
                  </ul>
                </div>
              </div>
            </>
          )}

          {screenWidth >= 900&& (
            <div className="frame-690">
              <div className="frame-691">
                <div className="frame-692">
                  <img className="logo-23" alt="Rust Oasis" src="../../Img/logo1.png"  />
                  <div className="frame-683">
                   <SelectBox
                    shape="round"
                    indicator={<Img src="images/img_arrowdown.svg" alt="" className="h-[16px] w-[14px]" />}
                    getOptionLabel={(e) => (
                      <>
                        <div className="flex items-center">
                          {/* <Img src="images/img_grid.png" alt="grid" className="h-[16px] w-[20px]" /> */}
                          <a>{e.label}</a>
                        </div>
                      </>
                    )}
                    name="grid"
                    placeholder={`ENG`}
                    options={dropDownOptions}
                    className="w-[38%] gap-1.5 font-semibold"
                  />
                  </div>
                   
                </div>
                <div className="frame-693">
                  <div className="frame-694">
                    <div className="frame-695">
                      <div className="text-wrapper-398">18+</div>
                    </div>
                    <p className="by-accessing-this-2">
                      <span className="text-wrapper-399">By accessing this site, you confirm that you are </span>
                      <span className="text-wrapper-400">over 18 years old</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="gap-list-2">
                <ul className="frame-697">
                  <li className="text-wrapper-401">Games</li>
                  <li className="text-wrapper-402">Roulette</li>
                  <li className="text-wrapper-402">Case Battle</li>
                  <li className="text-wrapper-402">Cases</li>
                  <li className="text-wrapper-402">Upgrader</li>
                </ul>
                <ul className="frame-697">
                  <li className="text-wrapper-401">Information</li>
                  <a href="/faq" className="text-wrapper-402">FAQ</a>
                  <a href="/terms-of-service" className="text-wrapper-402">Terms of Use</a>
                  <a href="/privacy-policy" className="text-wrapper-402">Privacy Policy</a>
                  <li className="text-wrapper-402">About us</li>
                </ul>
                </div>
            </div>
          )}
        </div>
        <div className="frame-698">
          <img
            className="vector-54"
            alt="Vector"
            src={screenWidth >= 900 ? "/img/vector-144.png" : screenWidth < 900 ? "/img/vector-144-1.png" : undefined}
          />
          <div
            className="frame-699"
            style={{
              alignItems: screenWidth >= 900? "center" : screenWidth < 900 ? "flex-start" : undefined,
              flexDirection: screenWidth < 900? "column" : undefined,
              gap: screenWidth >= 900 ? "522px" : screenWidth < 900 ? "24px" : undefined,
              justifyContent: screenWidth >= 900 ? "space-around" : undefined,
            }}
          >
            {screenWidth >= 900 && (
              <div className="frame-682 scrollable-area-footer">
                <p className="rast-oasis-the ">
                  <span className="text-wrapper-403">© Rast Oasis</span>
                  <span className="text-wrapper-404">
                    {" "}
                    The personal information you provide (name, address, phone number, e-mail, credit card number) is
                    confidential and not subject to disclosure.
                  </span>
                </p>
                <div className="frame-700">
                  <div className="frame-701">
                    <div className="text-wrapper-405">support@gptwin.com</div>
                    <p className="text-wrapper-406">+ 380 000 00 00</p>
                  </div>
                  <div className="text-wrapper-407">Zuikertuin Tower, Кюрасао</div>
                </div>
              </div>
            )}

            {screenWidth < 900 && (
              <>
                <p className="rast-oasis-the-2">
                  <span className="text-wrapper-403">© Rast Oasis</span>
                  <span className="text-wrapper-404">
                    {" "}
                    The personal information you provide (name, address, phone number, e-mail, credit card number) is
                    confidential and not subject to disclosure.
                  </span>
                </p>
                <div className="frame-697">
                  <div className="frame-701">
                    <div className="text-wrapper-405">support@gptwin.com</div>
                    <p className="text-wrapper-406">+ 380 000 00 00</p>
                  </div>
                  <div className="text-wrapper-408">Zuikertuin Tower, Кюрасао</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
