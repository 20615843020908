import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

const shapes = {
  round: "rounded-lg",
};

const variants = {
  outline: {
    blue_gray_900: "text-white-a700",
    gray_900_06: " text-white-a700",
  },
  fill: {
    blue_gray_900: "text-white-a700",
    gray_900_06: " text-white-a700",
  },
};

const sizes = {
  sm: "h-[44px] px-3.5 text-[16px]",
  md: "h-[48px] px-3.5 text-[16px]",
  xs: "h-[38px] px-2 text-[13px]",
};

const SelectBox = React.forwardRef(
  (
    {
      children,
      className = "",
      options = [],
      isSearchable = false,
      isMulti = false,
      indicator,
      shape,
      variant = "fill",
      size = "xs",
      color = "gray_900_06",
      ...restProps
    },
    ref
  ) => {
    return (
      <>
        <Select
          ref={ref}
          options={options}
          className={`${className} flex ${shape && shapes[shape]} ${size && sizes[size]} ${
            variant && variants[variant]?.[color]
          }`}
          isSearchable={isSearchable}
          isMulti={isMulti}
          components={{
            IndicatorSeparator: () => null,
            ...(indicator && { DropdownIndicator: () => indicator }),
          }}
          styles={{
            indicatorsContainer: (provided) => ({
              ...provided,
              padding: undefined,
              flexShrink: undefined,
              width: "max-content",
              "& > div": { padding: 0 },
            }),
            container: (provided) => ({
              ...provided,
              zIndex: 0,
              alignItems: "center",
              backgroundColor: "#242528", // Here set the background color to #242528
            }),
            control: (provided) => ({
              ...provided,
              backgroundColor: "transparent",
              border: "0 !important",
              boxShadow: "none !important",
              minHeight: "auto",
              width: "100%",
              flexWrap: undefined,
              ":hover": {
                backgroundColor: "#242528", // No change in background color on hover
                border: "0 !important",
              },
            }),
            input: (provided) => ({
              ...provided,
              color: "inherit",
            }),
            option: (provided, state) => ({
              ...provided,
              display: "flex",
              minWidth: "max-content",
              width: "100%",
              backgroundColor: state.isSelected ? "#1db904" : "#242528", // Set background color to #242528 here
              color: state.isSelected ? "#ffffff" : "inherit",
              ":hover": {
                backgroundColor: "#1C1D1F", // Set hover background color to #1C1D1F
                color: "#ffffff",
              },
            }),

            singleValue: (provided) => ({
              ...provided,
              display: "flex",
              marginLeft: undefined,
              marginRight: undefined,
            }),

            valueContainer: (provided) => ({
              ...provided,
              padding: 0,
              display: "flex",
              flexWrap: undefined,
            }),
            placeholder: (provided) => ({
              ...provided,
              margin: 0,
            }),
            menuPortal: (base) => ({ ...base, zIndex: 999999 }),
            menu: (base) => ({ ...base, minWidth: "max-content", width: "max-content" }),
        }}
        menuPortalTarget={document.body}
        closeMenuOnScroll={(event) => {
          return event.target.id === "scrollContainer";
        }}
        {...restProps}
      />
      {children}
    </>
  );
},
);

SelectBox.propTypes = {
className: PropTypes.string,
options: PropTypes.array,
isSearchable: PropTypes.bool,
isMulti: PropTypes.bool,
onChange: PropTypes.func,
value: PropTypes.string,
indicator: PropTypes.node,
shape: PropTypes.oneOf(["round"]),
size: PropTypes.oneOf(["sm", "md", "xs"]),
variant: PropTypes.oneOf(["outline", "fill"]),
color: PropTypes.oneOf(["blue_gray_900", "gray_900_06"]),
};

export { SelectBox };


