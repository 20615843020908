import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { HeaderMobile } from "../HeaderMobile";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Gb } from "../../components/Gb";
import "./style.css";
import { LiveChat } from "../LiveChat/LiveChat";

export const HomePage = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="home-page">
      <div
        className="div-28"
        style={{
          height: screenWidth < 900 ? "852px" : screenWidth >= 900 ? "1080px" : undefined,
          position: screenWidth >= 900 ? "relative" : undefined,
          width: screenWidth < 900 ? "375px" : screenWidth >= 900 ? "100%" : undefined,
        }}
      >
        {screenWidth <  900 && (
          <>
            <div className="overlap-102">
              <div className="frame-933">
                <div className="frame-934">
                  <div className="overlap-group-165">
                    <div className="ellipse-128" />
                    <img className="union-2" alt="Union" src="/img/union-1.svg" />
                    <p className="text-wrapper-556">Rust Gambling: Get Free Cases Daily on Our Site</p>
                    <p className="text-wrapper-557">Sign in with Steam easily and get rewards</p>
                    <img className="image-26" alt="Image" src="/img/image-10.png" />
                    <div className="frame-935">
                      <div className="frame-936">
                        <div className="text-wrapper-558">Sign in</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-937">
                  <div className="frame-938">
                    <div className="frame-939">
                      <div className="frame-940">
                        <div className="overlap-group-166">
                          <img className="vector-70" alt="Vector" src="/img/vector-6.svg" />
                          <div className="ellipse-129" />
                          <div className="ellipse-130" />
                          <div className="frame-941">
                            <img className="img-35" alt="Frame" src="/img/frame-64-22.svg" />
                            <div className="text-wrapper-559">Roulette</div>
                          </div>
                          <img className="img-36" alt="Img" src="/img/img-1.png" />
                          <div className="frame-942" />
                          <div className="frame-943">
                            <div className="ellipse-131" />
                            <div className="text-wrapper-560">0</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-944">
                        <div className="overlap-103">
                          <div className="ellipse-132" />
                          <img className="vector-71" alt="Vector" src="/img/vector-7.svg" />
                          <div className="ellipse-133" />
                          <div className="frame-945">
                            <img className="img-35" alt="Frame" src="/img/frame-64-17.svg" />
                            <div className="text-wrapper-559">Upgrader</div>
                          </div>
                          <img className="image-27" alt="Image" src="/img/image-13.png" />
                          <div className="frame-946" />
                          <div className="frame-947">
                            <div className="ellipse-131" />
                            <div className="text-wrapper-560">0</div>
                          </div>
                        </div>
                        <img className="up-arrow-5" alt="Up arrow" src="/img/chevron-down-1.png" />
                        <img className="up-arrow-6" alt="Up arrow" src="/img/chevron-down-1.png" />
                      </div>
                    </div>
                    <div className="div-29">
                      <p className="text-wrapper-561">Deposit crypto easily with all payment methods</p>
                      <div className="frame-948">
                        <img className="vector-72" alt="Vector" src="/img/vector-8.svg" />
                        <img className="group-62" alt="Group" src="/img/group-289503.png" />
                        <img className="group-63" alt="Group" src="/img/group.png" />
                        <img className="vector-73" alt="Vector" src="/img/vector-9.svg" />
                        <img className="logo-31" alt="Logo" src="/img/logo-12.png" />
                        <div className="logo-32">
                          <div className="bitcoin-logo-2">
                            <img className="subtract-2" alt="Subtract" src="/img/chevron-down-1.png" />
                            <img className="vector-74" alt="Vector" src="/img/chevron-down-1.png" />
                          </div>
                        </div>
                        <img className="logo-33" alt="Logo" src="/img/chevron-down-1.png" />
                        <div className="logo-34">
                          <div className="tether-logo-logo-2">
                            <div className="group-64" />
                            <img className="vector-75" alt="Vector" src="/img/chevron-down-1.png" />
                          </div>
                        </div>
                        <img className="logo-35" alt="Logo" src="/img/chevron-down-1.png" />
                        <img className="logo-36" alt="Logo" src="/img/chevron-down-1.png" />
                        <img className="logo-37" alt="Logo" src="/img/chevron-down-1.png" />
                        <img className="logo-38" alt="Logo" src="/img/chevron-down-1.png" />
                        <img className="logo-39" alt="Logo" src="/img/chevron-down-1.png" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-949">
                    <div className="frame-950">
                      <img className="frame-951" alt="Frame" src="/img/frame-64-3.svg" />
                      <div className="text-wrapper-562">Live bets</div>
                    </div>
                    <div className="div-29">
                      <div className="frame-952">
                        <div className="frame-953">
                          <div className="frame-954">
                            <div className="frame-951">
                              <div className="overlap-group-167">
                                <img className="star-75" alt="Star" src="/img/star-1-7-2x.png" />
                                <img className="star-76" alt="Star" src="/img/star-2-24.svg" />
                              </div>
                            </div>
                            <div className="text-wrapper-563">ツ HXNRY 1X ツ</div>
                          </div>
                          <div className="frame-955">
                            <div className="frame-954">
                              <img className="img-35" alt="Frame" src="/img/frame-64-19.svg" />
                              <div className="text-wrapper-564">Roulette</div>
                            </div>
                            <div className="text-wrapper-565">20 sec.</div>
                          </div>
                        </div>
                        <div className="frame-956">
                          <div className="frame-957">
                            <div className="text-wrapper-566">Bet</div>
                            <div className="frame-958">
                              <img className="img-35" alt="Storage" src="/img/storage-5634434-1.svg" />
                              <div className="text-wrapper-564">789</div>
                            </div>
                          </div>
                          <div className="frame-957">
                            <div className="text-wrapper-567">X</div>
                            <div className="text-wrapper-563">14.5x</div>
                          </div>
                          <div className="frame-957">
                            <div className="text-wrapper-567">Result</div>
                            <div className="frame-959">
                              <img className="img-35" alt="Storage" src="/img/storage-5634434-1-1.svg" />
                              <div className="text-wrapper-564">+ 789</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-952">
                        <div className="frame-953">
                          <div className="frame-954">
                            <div className="frame-951">
                              <div className="overlap-group-167">
                                <img className="star-75" alt="Star" src="/img/star-1-7-2x.png" />
                                <img className="star-76" alt="Star" src="/img/star-2-25.svg" />
                              </div>
                            </div>
                            <div className="text-wrapper-563">ツ HXNRY 1X ツ</div>
                          </div>
                          <div className="frame-955">
                            <div className="frame-954">
                              <img className="img-35" alt="Frame" src="/img/frame-64-19.svg" />
                              <div className="text-wrapper-564">Roulette</div>
                            </div>
                            <div className="text-wrapper-565">20 sec.</div>
                          </div>
                        </div>
                        <div className="frame-956">
                          <div className="frame-957">
                            <div className="text-wrapper-566">Bet</div>
                            <div className="frame-958">
                              <img className="img-35" alt="Storage" src="/img/storage-5634434-1.svg" />
                              <div className="text-wrapper-564">789</div>
                            </div>
                          </div>
                          <div className="frame-957">
                            <div className="text-wrapper-567">X</div>
                            <div className="text-wrapper-563">14.5x</div>
                          </div>
                          <div className="frame-957">
                            <div className="text-wrapper-567">Result</div>
                            <div className="frame-959">
                              <img className="img-35" alt="Storage" src="/img/storage-5634434-1-1.svg" />
                              <div className="text-wrapper-564">+ 789</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-952">
                        <div className="frame-953">
                          <div className="frame-954">
                            <div className="frame-951">
                              <div className="overlap-group-167">
                                <img className="star-75" alt="Star" src="/img/star-1-7-2x.png" />
                                <img className="star-76" alt="Star" src="/img/star-2-26.svg" />
                              </div>
                            </div>
                            <div className="text-wrapper-563">ツ HXNRY 1X ツ</div>
                          </div>
                          <div className="frame-955">
                            <div className="frame-954">
                              <img className="img-35" alt="Frame" src="/img/frame-64-19.svg" />
                              <div className="text-wrapper-564">Roulette</div>
                            </div>
                            <div className="text-wrapper-565">20 sec.</div>
                          </div>
                        </div>
                        <div className="frame-956">
                          <div className="frame-957">
                            <div className="text-wrapper-566">Bet</div>
                            <div className="frame-958">
                              <img className="img-35" alt="Storage" src="/img/storage-5634434-1.svg" />
                              <div className="text-wrapper-564">789</div>
                            </div>
                          </div>
                          <div className="frame-957">
                            <div className="text-wrapper-567">X</div>
                            <div className="text-wrapper-563">14.5x</div>
                          </div>
                          <div className="frame-957">
                            <div className="text-wrapper-567">Result</div>
                            <div className="frame-959">
                              <img className="img-35" alt="Storage" src="/img/storage-5634434-1-1.svg" />
                              <div className="text-wrapper-564">+ 789</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <HeaderMobile />
            </div>
            <div className="frame-964">
              <div className="element-system-bar-11">
                <div className="group-65">
                  <div className="battery-11">
                    <div className="overlap-group-168">
                      <div className="capacity-11" />
                    </div>
                    <img className="cap-11" alt="Cap" src="/img/cap-4.svg" />
                  </div>
                  <img className="wifi-11" alt="Wifi" src="/img/wifi-1.svg" />
                  <img
                    className="cellular-connection-11"
                    alt="Cellular connection"
                    src="/img/cellular-connection-3.svg"
                  />
                </div>
                <div className="starus-10">
                  <p className="time-11">
                    <span className="text-wrapper-571">9:4</span>
                    <span className="text-wrapper-572">1</span>
                  </p>
                </div>
                <div className="floating-island-11" />
              </div>
              <div className="frame-965">
                <img className="logo-40" alt="Logo" src="/img/logo-19.svg" />
                <div className="frame-939">
                  <div className="frame-966">
                    <div className="frame-954">
                      <Gb className="gb-14" />
                      <div className="text-wrapper-573">English</div>
                    </div>
                    <ChevronDownWrapper />
                  </div>
                  <div className="frame-967">
                    <div className="frame-968">
                      <div className="text-wrapper-573">Sign in</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {screenWidth >=  900 && (
          <div className="overlap-105">
            <div className="overlap-106">
              <div className="content-field scrollable-area">
                {/* cards */}
                <div className="overlap-group-wrapper">
                  <div className="overlap-2">
                    <div className="overlap-group-2">
                      <div className="ellipse-2" />
                      <img className="union" alt="Union" src="https://c.animaapp.com/AY1MtytX/img/union.png" />
                      <p className="text-wrapper-4">Rust Gambling: Get Free Cases Daily on Our Site</p>
                      <img className="image" alt="Image" src="https://c.animaapp.com/AY1MtytX/img/image-10@2x.png" />
                      <div className="frame-wrapper">
                        <button className="frame-6">
                          <div className="text-wrapper-2">Sign in</div>
                        </button>
                      </div>
                    </div>
                    <p className="text-wrapper-5">Sign in with Steam easily and get rewards</p>
                  </div>
                </div>
                <div className="frame-7">
                  <div className="overlap-3">
                    <img className="vector-3" alt="Vector" src="https://c.animaapp.com/AY1MtytX/img/vector-2@2x.png" />
                    <div className="ellipse-3" />
                    <div className="ellipse-4" />
                    <div className="frame-8">
                      <img className="frame-9" alt="Frame" src="https://c.animaapp.com/AY1MtytX/img/frame-64@2x.png" />
                      <div className="text-wrapper-6">Roulette</div>
                    </div>
                    <img className="img-2" alt="Img" src="https://c.animaapp.com/AY1MtytX/img/img@2x.png" />
                    <div className="frame-10" />
                    <div className="frame-11">
                      <div className="ellipse-5" />
                      <div className="text-wrapper-3">0</div>
                    </div>
                  </div>
                </div>
                <div className="frame-12">
                  <div className="overlap-4">
                    <img className="vector-4" alt="Vector" src="https://c.animaapp.com/AY1MtytX/img/vector-3@2x.png" />
                    <div className="ellipse-6" />
                    <img className="image-2" alt="Image" src="https://c.animaapp.com/AY1MtytX/img/image-12@2x.png" />
                    <div className="frame-13">
                      <img className="frame-9" alt="Frame" src="https://c.animaapp.com/AY1MtytX/img/frame-64-1@2x.png" />
                      <div className="text-wrapper-6">Case battles</div>
                    </div>
                    <div className="frame-14">
                      <div className="ellipse-5" />
                      <div className="text-wrapper-3">0</div>
                    </div>
                    <div className="frame-15" />
                    <img className="image-3" alt="Image" src="https://c.animaapp.com/AY1MtytX/img/image-11@2x.png" />
                    <div className="ellipse-7" />
                    <img className="image-4" alt="Image" src="https://c.animaapp.com/AY1MtytX/img/image-6@2x.png" />
                    <div className="frame-16">
                      <div className="text-wrapper-2">POPULAR</div>
                    </div>
                  </div>
                </div>
                <div className="frame-26">
                  <div className="overlap-5">
                    <div className="ellipse-8" />
                    <img
                      className="up-arrow"
                      alt="Up arrow"
                      src="https://c.animaapp.com/AY1MtytX/img/up-arrow-1@2x.png"
                    />
                    <img className="vector-5" alt="Vector" src="https://c.animaapp.com/AY1MtytX/img/vector-5@2x.png" />
                    <div className="ellipse-9" />
                    <div className="frame-13">
                      <img className="frame-9" alt="Frame" src="https://c.animaapp.com/AY1MtytX/img/frame-64-13@2x.png" />
                      <div className="text-wrapper-6">Upgrader</div>
                    </div>
                    <img className="image-5" alt="Image" src="https://c.animaapp.com/AY1MtytX/img/image-13@2x.png" />
                    <div className="frame-14">
                      <div className="ellipse-5" />
                      <div className="text-wrapper-3">0</div>
                    </div>
                    <img
                      className="up-arrow-2"
                      alt="Up arrow"
                      src="https://c.animaapp.com/AY1MtytX/img/up-arrow-2@2x.png"
                    />
                    <div className="frame-27" />
                  </div>
                </div>
                <div className="frame-28">
                  <div className="overlap-5">
                    <img className="vector-5" alt="Vector" src="https://c.animaapp.com/AY1MtytX/img/vector-5@2x.png" />
                    <div className="ellipse-9" />
                    <img
                      className="lentochkinar-open"
                      alt="Lentochkinar open"
                      src="https://c.animaapp.com/AY1MtytX/img/lentochkinar-open-metalic-game-box-isometric-smooth-game-style--@2x.png"
                    />
                    <div className="frame-13">
                      <img className="frame-9" alt="Frame" src="https://c.animaapp.com/AY1MtytX/img/frame-64-14@2x.png" />
                      <div className="text-wrapper-6">Open cases</div>
                    </div>
                    <div className="frame-14">
                      <div className="ellipse-5" />
                      <div className="text-wrapper-3">0</div>
                    </div>
                    <div className="frame-27" />
                    <img
                      className="subtract-2"
                      alt="Subtract"
                      src="https://c.animaapp.com/AY1MtytX/img/subtract-2@2x.png"
                    />
                    <img
                      className="subtract-3"
                      alt="Subtract"
                      src="https://c.animaapp.com/AY1MtytX/img/subtract-3@2x.png"
                    />
                  </div>
                </div>

                <div className="deposit">
                  <p className="p">Deposit crypto easily with all payment methods</p>
                  {/* logos cryptocurrency, banks, etc */}
                  <div className="frame-5">
                    <img className="logo-2" alt="Logo" src="https://c.animaapp.com/AY1MtytX/img/logo-1@2x.png" />
                    <img className="logo-3" alt="Logo" src="https://c.animaapp.com/AY1MtytX/img/logo-2@2x.png" />
                    <img className="logo-4" alt="Logo" src="https://c.animaapp.com/AY1MtytX/img/logo-3@2x.png" />
                    <img className="logo-4" alt="Logo" src="https://c.animaapp.com/AY1MtytX/img/logo-4@2x.png" />
                    <img className="logo-4" alt="Logo" src="https://c.animaapp.com/AY1MtytX/img/logo-5@2x.png" />
                    <div className="logo-5">
                      <div className="bitcoin-logo">
                        <img
                          className="subtract"
                          alt="Subtract"
                          src="https://c.animaapp.com/AY1MtytX/img/subtract@2x.png"
                        />
                        <img className="vector" alt="Vector" src="https://c.animaapp.com/AY1MtytX/img/vector@2x.png" />
                      </div>
                    </div>
                    <img className="logo-5" alt="Logo" src="https://c.animaapp.com/AY1MtytX/img/logo-6@2x.png" />
                    <div className="logo-6">
                      <div className="tether-logo-logo">
                        <div className="group" />
                        <img
                          className="vector-2"
                          alt="Vector"
                          src="https://c.animaapp.com/AY1MtytX/img/vector-1@2x.png"
                        />
                      </div>
                    </div>
                    <img className="logo-6" alt="Logo" src="https://c.animaapp.com/AY1MtytX/img/logo-7@2x.png" />
                    <img className="logo-7" alt="Logo" src="https://c.animaapp.com/AY1MtytX/img/logo-8@2x.png" />
                    <img className="logo-7" alt="Logo" src="https://c.animaapp.com/AY1MtytX/img/logo-9@2x.png" />
                    <img className="logo-7" alt="Logo" src="https://c.animaapp.com/AY1MtytX/img/logo-10@2x.png" />
                    <img className="logo-7" alt="Logo" src="https://c.animaapp.com/AY1MtytX/img/logo-11@2x.png" />
                  </div>

                </div>
                <br></br>
                <div className="recent-wins">
                  <div className="frame-987">
                    <img className="frame-951" alt="Frame" src="./Img/live-bet.png" />
                    <div className="text-wrapper-579">Live bets</div>
                  </div>
                  <div className="group-67 scrollable-area">
                    <div className="frame-988">
                    <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-989">
                        <div className="frame-990">
                          <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />
                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>
                      <div className="frame-994">
                        <div className="frame-990">
                        <img className="frame-951" alt="Frame" src="./Img/roulette.svg" />
                          <div className="text-wrapper-579">Roulette</div>
                        </div>
                        <div className="frame-991">
                          <div className="frame-951">
                            <div className="overlap-group-167">
                              <img className="star-75" alt="Star" src="./Img/star.svg" />

                            </div>
                          </div>
                          <div className="text-wrapper-579">ツ HXNRY 1X ツ</div>
                        </div>
                        <div className="text-wrapper-580">20 sec ago</div>
                        <div className="frame-992">
                          <img className="img-35" alt="Storage" src="./Img/storage.svg" />
                          <div className="text-wrapper-581">789</div>
                        </div>
                        <div className="frame-993">
                          <img className="img-35" alt="Storage" src="./Img/storage-green.svg" />
                          <div className="text-wrapper-581">+ 789</div>
                        </div>
                        <div className="text-wrapper-582">14.5x</div>
                      </div>

                    </div>
                    <div className="group-68">
                      <div className="text-wrapper-583">Game</div>
                      <div className="text-wrapper-584">User</div>
                      <div className="text-wrapper-585">ITEM</div>
                      <div className="text-wrapper-586">Time</div>
                      <div className="text-wrapper-587">Bet</div>
                      <div className="text-wrapper-588">Multiplayer</div>
                      <div className="text-wrapper-589">Result</div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <LiveChat />

            {/* <div className="overlap-111"> */}



          </div>
        )}
      </div>
    </div>
  );
};
