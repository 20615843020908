import React from "react";
import { SelectBox } from "react-select";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import "./style.css";

export const HeaderMobile = () => {
    const screenWidth = useWindowWidth();


    return (

                    <>
                        <div className="frame-960">
                            <div className="overlap-104">
                                <div className="frame-961">
                                    <div className="frame-962">
                                        <img className="img-37" alt="Home" src="/img/home-06-1.svg" />
                                        <div className="text-wrapper-568">Home</div>
                                    </div>
                                    <div className="frame-963">
                                        <img className="img-37" alt="Gameboy" src="/img/gameboy-1.svg" />
                                        <div className="text-wrapper-569">Games</div>
                                    </div>
                                    <div className="frame-963">
                                        <img className="img-37" alt="Chatting" src="/img/chatting-01.svg" />
                                        <div className="text-wrapper-569">Chat</div>
                                    </div>
                                    <div className="frame-963">
                                        <img className="img-37" alt="Vuesax bold add" src="/img/vuesax-bold-add-circle.svg" />
                                        <div className="text-wrapper-570">Sign In</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                )};
