import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import { VuesaxBoldWallet } from "../../components/VuesaxBoldWallet";
import { LiveChat } from "../LiveChat/LiveChat";
import "./style.css";

export const PrivacyPolicy = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="privacy-policy">
      <div
        className="div-23"
        style={{
          height: screenWidth < 900 ? "852px" : screenWidth >= 900 ? "1080px" : undefined,
          position: screenWidth >= 900 ? "relative" : undefined,
          width: screenWidth < 900 ? "375px" : screenWidth >= 900 ? "100%" : undefined,
        }}
      >
        {screenWidth < 900 && (
          <div className="overlap-89">
            <img className="vector-66" alt="Vector" src="/img/vector-2.png" />
            <div className="ellipse-119" />
            <div className="rectangle-75" />
            <div className="frame-848">
              <div className="frame-849">
                <div className="text-wrapper-497">Privacy policy</div>
                <div className="frame-850">
                  <p className="text-wrapper-498">Answers to your frequently asked questions</p>
                  <div className="frame-851">
                    <img
                      className="vuesax-linear-rotate-3"
                      alt="Vuesax linear rotate"
                      src="./Img/reload.svg"
                    />
                    <div className="text-wrapper-499">Updated February 5, 2024</div>
                  </div>
                </div>
              </div>
              <div className="frame-852">
                <div className="frame-853">
                  <div className="frame-854">
                    <button className="text-wrapper-500">Privacy policy</button>
                  </div>
                  <div className="frame-855">
                    <button className="text-wrapper-499">FAQ</button>
                  </div>
                  <div className="frame-856">
                    <button className="text-wrapper-501">Terms of service</button>
                  </div>
                </div>
                <div className="frame-857">
                  <div className="frame-858">
                    <div className="text-wrapper-502">Information We Collect</div>
                    <div className="flexcontainer-2">
                      <p className="text-4">
                        <span className="text-wrapper-503">
                          We may collect the following types of information:
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">
                          Personal Identification Information: Name, email address, phone number, date of birth, and
                          payment information.
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">
                          Technical Data: IP address, browser type, operating system, and device information.
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">
                          Usage Data: Details about your gaming activity, preferences, and interactions with our
                          website.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="frame-858">
                    <p className="text-wrapper-502">How We Use Your Information</p>
                    <div className="flexcontainer-2">
                      <p className="text-5">
                        <span className="text-wrapper-505">
                          We use the information we collect for various purposes, including:
                          <br />
                        </span>
                      </p>
                      <p className="text-5">
                        <span className="text-wrapper-506">
                          To create and manage your account.
                          <br />
                        </span>
                      </p>
                      <p className="text-5">
                        <span className="text-wrapper-506">
                          To process transactions and payments.
                          <br />
                        </span>
                      </p>
                      <p className="text-5">
                        <span className="text-wrapper-506">
                          To improve our services and website functionality.
                          <br />
                        </span>
                      </p>
                      <p className="text-5">
                        <span className="text-wrapper-506">
                          To communicate with you about promotions, updates, and customer support.
                          <br />
                        </span>
                      </p>
                      <p className="text-5">
                        <span className="text-wrapper-506">To comply with legal and regulatory obligations.</span>
                      </p>
                    </div>
                  </div>
                  <div className="frame-858">
                    <div className="text-wrapper-502">Introduction</div>
                    <p className="text-wrapper-507">
                      Welcome to [Casino Name]. We are committed to protecting your personal information and ensuring
                      your experience with us is safe and enjoyable. This Privacy Policy outlines how we collect, use,
                      and safeguard your personal data.
                    </p>
                  </div>
                  <div className="frame-858">
                    <p className="text-wrapper-502">How We Use Your Information</p>
                    <div className="flexcontainer-2">
                      <p className="text-4">
                        <span className="text-wrapper-503">
                          We use the information we collect for various purposes, including:
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">
                          To create and manage your account.
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">
                          To process transactions and payments.
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">
                          To improve our services and website functionality.
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">
                          To communicate with you about promotions, updates, and customer support.
                          <br />
                        </span>
                      </p>
                      <p className="text-4">
                        <span className="text-wrapper-504">To comply with legal and regulatory obligations.</span>
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="rectangle-76" />
            <div className="frame-859">
              <div className="overlap-group-153">
                <div className="element-indicator-9">
                </div>
                <div className="frame-860">
                  <div className="frame-861">
                    <img className="img-32" alt="Home" src="/img/home-06-2.png" />
                    <div className="text-wrapper-508">Home</div>
                  </div>
                  <div className="frame-862">
                    <img className="img-32" alt="Gameboy" src="/img/gameboy-2.png" />
                    <div className="text-wrapper-509">Games</div>
                  </div>
                  <div className="frame-862">
                    <img className="img-32" alt="Chatting" src="/img/chatting-01.png" />
                    <div className="text-wrapper-509">Chat</div>
                  </div>
                  <div className="frame-862">
                    <div className="img-32">
                      <div className="frame-863">
                        <img className="rectangle-77" alt="Rectangle" src="/img/rectangle-1-1.png" />
                      </div>
                    </div>
                    <div className="text-wrapper-509">Settings</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-864">
              <div className="element-system-bar-10">
                <div className="group-57">
                  <div className="battery-10">
                    <div className="overlap-group-154">
                      <div className="capacity-10" />
                    </div>
                    <img className="cap-10" alt="Cap" src="/img/cap-1.png" />
                  </div>
                  <img className="wifi-10" alt="Wifi" src="/img/wifi-1.png" />
                  <img
                    className="cellular-connection-10"
                    alt="Cellular connection"
                    src="/img/cellular-connection-3.png"
                  />
                </div>
                <div className="starus-9">
                  <p className="time-10">
                    <span className="text-wrapper-510">9:4</span>
                    <span className="text-wrapper-511">1</span>
                  </p>
                </div>
                <div className="floating-island-10" />
              </div>
              <div className="frame-865">
                <img className="logo-28" alt="Logo" src="/img/logo-2.png" />
                <div className="frame-866">
                  <div className="frame-867">
                    <img className="img-32" alt="Storage" src="/img/storage-5634434-1-2.png" />
                    <div className="text-wrapper-512">789</div>
                  </div>
                  <div className="frame-851">
                    <div className="frame-868">
                      <div className="frame-869">
                        <div className="text-wrapper-513">Deposit</div>
                      </div>
                    </div>
                    <div className="frame-870">
                      <VuesaxBoldWallet
                        className="vuesax-bold-wallet-19"
                        vuesaxBoldWallet="/img/vuesax-bold-wallet-2-3.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {screenWidth >= 900 && (
          <>
            <div className="overlap-90">
              <img className="vector-68" alt="Vector" src="./Img/vector_background.svg" />
              <div className="ellipse-120" />
              <div className="frame-871">
                <div className="frame-872">
                  <div className="text-wrapper-514">Privacy policy</div>
                  <div className="frame-850">
                    <p className="text-wrapper-515">Set of rules for using the service</p>
                    <div className="frame-851">
                      <img
                        className="vuesax-linear-rotate-4"
                        alt="Vuesax linear rotate"
                        src="./Img/reload.svg"
                      />
                      <div className="text-wrapper-516">Updated February 5, 2024</div>
                    </div>
                  </div>
                </div>
                <div className="frame-873">
                  <div className="frame-874">
                    <button className="frame-875">
                      <a className="text-wrapper-517" href="/privacy-policy">Privacy policy</a>
                    </button>
                    <button className="frame-855">
                      <a className="text-wrapper-518" href="/FAQ" >FAQ</a>
                    </button>
                    <button className="frame-876">
                      <a className="text-wrapper-518" href="/terms-of-service">Terms of service</a>
                    </button>
                  </div>
                  {/* text in the frame */}
                  <div className="frame-877 scrollable-area">
                    <div className="frame-858">
                      <div className="text-wrapper-519">Introduction</div>
                      <p className="div-24">
                        <span className="text-wrapper-520">
                          Welcome to [Casino Name]. We are committed to protecting your personal information and
                          ensuring your experience with us is safe and enjoyable.{" "}
                        </span>
                        <span className="text-wrapper-521">
                          This Privacy Policy outlines how we collect, use, and safeguard your personal data.
                        </span>
                      </p>
                    </div>
                    <div className="frame-858">
                      <div className="text-wrapper-519">Information We Collect</div>
                      <div className="flexcontainer-2">
                        <p className="div-24">
                          <span className="text-wrapper-522">
                            We may collect the following types of information:
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">
                            · Personal Identification Information: Name, email address, phone number, date of birth, and
                            payment information.
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">
                            · Technical Data: IP address, browser type, operating system, and device information.
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">
                            · Usage Data: Details about your gaming activity, preferences, and interactions with our
                            website.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="frame-858">
                      <p className="text-wrapper-519">How We Use Your Information</p>
                      <div className="flexcontainer-2">
                        <p className="text-6">
                          <span className="text-wrapper-523">
                            We use the information we collect for various purposes, including:
                            <br />
                          </span>
                        </p>
                        <p className="text-6">
                          <span className="text-wrapper-524">
                            · To create and manage your account.
                            <br />
                          </span>
                        </p>
                        <p className="text-6">
                          <span className="text-wrapper-524">
                            · To process transactions and payments.
                            <br />
                          </span>
                        </p>
                        <p className="text-6">
                          <span className="text-wrapper-524">
                            · To improve our services and website functionality.
                            <br />
                          </span>
                        </p>
                        <p className="text-6">
                          <span className="text-wrapper-524">
                            · To communicate with you about promotions, updates, and customer support.
                            <br />
                          </span>
                        </p>
                        <p className="text-6">
                          <span className="text-wrapper-524">· To comply with legal and regulatory obligations.</span>
                        </p>
                      </div>
                    </div>
                    <div className="frame-858">
                      <div className="text-wrapper-519">Introduction</div>
                      <p className="text-wrapper-525">
                        Welcome to [Casino Name]. We are committed to protecting your personal information and ensuring
                        your experience with us is safe and enjoyable. This Privacy Policy outlines how we collect, use,
                        and safeguard your personal data.
                      </p>
                    </div>
                    <div className="frame-858">
                      <p className="text-wrapper-519">How We Use Your Information</p>
                      <div className="flexcontainer-2">
                        <p className="div-24">
                          <span className="text-wrapper-522">
                            We use the information we collect for various purposes, including:
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">
                            · To create and manage your account.
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">
                            · To process transactions and payments.
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">
                            · To improve our services and website functionality.
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">
                            · To communicate with you about promotions, updates, and customer support.
                            <br />
                          </span>
                        </p>
                        <p className="div-24">
                          <span className="text-wrapper-520">· To comply with legal and regulatory obligations.</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rectangle-66" />
            </div>
            <div className="side-bar-11">
              <img className="frame-886" />
              <div className="frame-887">
                <div className="text-wrapper-531">18+</div>
              </div>
            </div>
          <LiveChat/>
          </>
        )}
      </div>
    </div>
  );
};
