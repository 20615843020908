import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import { VuesaxBoldWallet } from "../../components/VuesaxBoldWallet";
import { X2 } from "../../icons/X2";
import "./style.css";

export const Withdrawal = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="withdrawal">
      <div
        className="overlap-wrapper-2"
        style={{
          height: screenWidth < 900 ? "818px" : screenWidth >= 900 ? "1080px" : undefined,
          width: screenWidth < 900 ? "375px" : screenWidth >= 900 ? "900px" : undefined,
        }}
      >
        <div
          className="overlap-36"
          style={{
            height: screenWidth < 900 ? "1011px" : screenWidth >= 900 ? "1093px" : undefined,
            width: screenWidth < 900 ? "1269px" : undefined,
          }}
        >
          {screenWidth < 900 && (
            <div className="group-17">
              <div className="frame-288">
                <div className="element-system-bar-4">
                  <div className="group-18">
                    <div className="battery-4">
                      <div className="overlap-group-34">
                        <div className="capacity-4" />
                      </div>
                      <img className="cap-4" alt="Cap" src="/img/cap-1.png" />
                    </div>
                    <img className="wifi-4" alt="Wifi" src="/img/wifi-1.png" />
                    <img
                      className="cellular-connection-4"
                      alt="Cellular connection"
                      src="/img/cellular-connection-3.png"
                    />
                  </div>
                  <div className="starus-3">
                    <p className="time-4">
                      <span className="text-wrapper-174">9:4</span>
                      <span className="text-wrapper-175">1</span>
                    </p>
                  </div>
                  <div className="floating-island-4" />
                </div>
                <div className="frame-289">
                  <div className="group-wrapper">
                    <div className="group-19">
                      <div className="frame-290">
                        <div className="text-wrapper-176">Rust</div>
                        <img className="up-arrow" alt="Up arrow" src="/img/up-arrow-2-1.png" />
                        <div className="text-wrapper-177">Oasis</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-291">
                    <div className="frame-292">
                      <div className="frame-293">
                        <Gb className="gb-4" />
                        <div className="text-wrapper-178">English</div>
                      </div>
                      <ChevronDownWrapper />
                    </div>
                    <div className="frame-294">
                      <div className="frame-295">
                        <div className="text-wrapper-178">Sign in</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlap-37">
                <div className="rectangle-24" />
                <div className="frame-296">
                  <div className="frame-297">
                    <div className="overlap-group-35">
                      <img className="frame-298" alt="Frame" src="/img/frame-2.png" />
                      <div className="ellipse-52" />
                      <img className="union" alt="Union" src="/img/union.png" />
                      <p className="text-wrapper-179">Rust Gambling: Get Free Cases Daily on Our Site</p>
                      <p className="text-wrapper-180">Sign in with Steam easily and get rewards</p>
                      <img className="image-16" alt="Image" src="/img/image-10.png" />
                      <div className="frame-299">
                        <div className="frame-300">
                          <div className="text-wrapper-181">Sign up</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-301">
                    <div className="frame-302">
                      <div className="frame-291">
                        <div className="frame-303">
                          <div className="overlap-group-36">
                            <img className="vector-14" alt="Vector" src="/img/vector-58.png" />
                            <div className="ellipse-53" />
                            <div className="ellipse-54" />
                            <div className="frame-304">
                              <img className="img-12" alt="Frame" src="/img/frame-64-40.png" />
                              <div className="text-wrapper-182">Roulette</div>
                            </div>
                            <img className="img-13" alt="Img" src="/img/img-2x.png" />
                            <div className="frame-305" />
                            <div className="frame-306">
                              <div className="ellipse-55" />
                              <div className="text-wrapper-183">456</div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-307">
                          <div className="overlap-38">
                            <div className="ellipse-56" />
                            <img className="vector-15" alt="Vector" src="/img/vector-59.png" />
                            <div className="ellipse-57" />
                            <div className="frame-308">
                              <img className="img-12" alt="Frame" src="/img/frame-64-36.png" />
                              <div className="text-wrapper-182">Upgrader</div>
                            </div>
                            <img className="image-17" alt="Image" src="/img/image-13.png" />
                            <div className="frame-309" />
                            <div className="frame-310">
                              <div className="ellipse-55" />
                              <div className="text-wrapper-183">456</div>
                            </div>
                          </div>
                          <img className="up-arrow-2" alt="Up arrow" src="/img/chevron-down-1.png" />
                          <img className="up-arrow-3" alt="Up arrow" src="/img/chevron-down-1.png" />
                        </div>
                      </div>
                      <div className="div-9">
                        <p className="text-wrapper-184">Deposit crypto easily with all payment methods</p>
                        <div className="frame-311">
                          <img className="vector-16" alt="Vector" src="/img/vector-60.png" />
                          <img className="group-20" alt="Group" src="/img/group-289503-2x.png" />
                          <img className="group-21" alt="Group" src="/img/group.png" />
                          <img className="vector-17" alt="Vector" src="/img/vector-61.png" />
                          <img className="logo-8" alt="Logo" src="/img/logo-12.png" />
                          <div className="bitcoin-logo-wrapper">
                            <div className="bitcoin-logo">
                              <img className="subtract" alt="Subtract" src="/img/chevron-down-1.png" />
                              <img className="vector-18" alt="Vector" src="/img/chevron-down-1.png" />
                            </div>
                          </div>
                          <img className="logo-9" alt="Logo" src="/img/chevron-down-1.png" />
                          <div className="tether-logo-logo-wrapper">
                            <div className="tether-logo-logo">
                              <div className="group-22" />
                              <img className="vector-19" alt="Vector" src="/img/chevron-down-1.png" />
                            </div>
                          </div>
                          <img className="logo-10" alt="Logo" src="/img/chevron-down-1.png" />
                          <img className="logo-11" alt="Logo" src="/img/chevron-down-1.png" />
                          <img className="logo-12" alt="Logo" src="/img/chevron-down-1.png" />
                          <img className="logo-13" alt="Logo" src="/img/chevron-down-1.png" />
                          <img className="logo-14" alt="Logo" src="/img/chevron-down-1.png" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-312">
                      <div className="frame-313">
                        <img className="group-23" alt="Frame" src="/img/frame-64-37.png" />
                        <div className="text-wrapper-185">Recent wins</div>
                      </div>
                      <div className="div-9">
                        <div className="frame-314">
                          <div className="frame-315">
                            <div className="frame-293">
                              <div className="group-23">
                                <div className="overlap-group-37">
                                  <img className="star-21" alt="Star" src="/img/star-1-7.png" />
                                  <img className="star-22" alt="Star" src="/img/star-2-156.png" />
                                </div>
                              </div>
                              <div className="text-wrapper-186">ツ HXNRY 1X ツ</div>
                            </div>
                            <div className="frame-316">
                              <div className="frame-293">
                                <img className="img-12" alt="Frame" src="/img/frame-64-38.png" />
                                <div className="text-wrapper-187">Roulette</div>
                              </div>
                              <div className="text-wrapper-188">20 sec.</div>
                            </div>
                          </div>
                          <div className="frame-317">
                            <div className="frame-318">
                              <div className="text-wrapper-189">Bet</div>
                              <div className="frame-319">
                                <img className="img-12" alt="Storage" src="/img/storage-5634434-1.png" />
                                <div className="text-wrapper-187">789</div>
                              </div>
                            </div>
                            <div className="frame-318">
                              <div className="text-wrapper-190">X</div>
                              <div className="text-wrapper-186">14.5x</div>
                            </div>
                            <div className="frame-318">
                              <div className="text-wrapper-190">Result</div>
                              <div className="frame-320">
                                <img className="img-12" alt="Storage" src="/img/storage-5634434-1-11.png" />
                                <div className="text-wrapper-187">+ 789</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-314">
                          <div className="frame-315">
                            <div className="frame-293">
                              <div className="group-23">
                                <div className="overlap-group-37">
                                  <img className="star-21" alt="Star" src="/img/star-1-7.png" />
                                  <img className="star-22" alt="Star" src="/img/star-2-156.png" />
                                </div>
                              </div>
                              <div className="text-wrapper-186">ツ HXNRY 1X ツ</div>
                            </div>
                            <div className="frame-316">
                              <div className="frame-293">
                                <img className="img-12" alt="Frame" src="/img/frame-64-38.png" />
                                <div className="text-wrapper-187">Roulette</div>
                              </div>
                              <div className="text-wrapper-188">20 sec.</div>
                            </div>
                          </div>
                          <div className="frame-317">
                            <div className="frame-318">
                              <div className="text-wrapper-189">Bet</div>
                              <div className="frame-319">
                                <img className="storage-20" alt="Storage" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-187">789</div>
                              </div>
                            </div>
                            <div className="frame-318">
                              <div className="text-wrapper-190">X</div>
                              <div className="text-wrapper-186">14.5x</div>
                            </div>
                            <div className="frame-318">
                              <div className="text-wrapper-190">Result</div>
                              <div className="frame-320">
                                <img className="storage-21" alt="Storage" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-187">+ 789</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-314">
                          <div className="frame-315">
                            <div className="frame-293">
                              <div className="group-23">
                                <div className="overlap-group-38">
                                  <img className="star-23" alt="Star" src="/img/star-1-7.png" />
                                  <img className="star-24" alt="Star" src="/img/chevron-down-1.png" />
                                </div>
                              </div>
                              <div className="text-wrapper-186">ツ HXNRY 1X ツ</div>
                            </div>
                            <div className="frame-316">
                              <div className="frame-293">
                                <img className="frame-321" alt="Frame" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-187">Roulette</div>
                              </div>
                              <div className="text-wrapper-188">20 sec.</div>
                            </div>
                          </div>
                          <div className="frame-317">
                            <div className="frame-318">
                              <div className="text-wrapper-189">Bet</div>
                              <div className="frame-319">
                                <img className="storage-22" alt="Storage" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-187">789</div>
                              </div>
                            </div>
                            <div className="frame-318">
                              <div className="text-wrapper-190">X</div>
                              <div className="text-wrapper-186">14.5x</div>
                            </div>
                            <div className="frame-318">
                              <div className="text-wrapper-190">Result</div>
                              <div className="frame-320">
                                <img className="storage-23" alt="Storage" src="/img/chevron-down-1.png" />
                                <div className="text-wrapper-187">+ 789</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rectangle-25" />
                <div className="frame-322">
                  <div className="overlap-39">
                    <div className="element-indicator-3">
                      <div className="line-13" />
                    </div>
                    <div className="frame-323">
                      <div className="frame-324">
                        <img className="img-14" alt="Home" src="/img/home-06-2.png" />
                        <div className="text-wrapper-191">Home</div>
                      </div>
                      <div className="frame-325">
                        <img className="img-14" alt="Gameboy" src="/img/gameboy-2.png" />
                        <div className="text-wrapper-192">Games</div>
                      </div>
                      <div className="frame-325">
                        <img className="img-14" alt="Chatting" src="/img/chatting-01.png" />
                        <div className="text-wrapper-192">Chat</div>
                      </div>
                      <div className="frame-325">
                        <img className="img-14" alt="Vuesax bold add" src="/img/vuesax-bold-add-circle.png" />
                        <div className="text-wrapper-193">Sign In</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {screenWidth >= 900 && (
            <>
              <header className="header-5">
                <div className="overlap-group-39">
                  <div className="ellipse-58" />
                  <img className="line-14" alt="Line" src="/img/line-1.png" />
                </div>
                <div className="frame-326">
                  <div className="row-5">
                    <img className="img-12" alt="Home" src="/img/home-06.png" />
                    <div className="text-wrapper-194">Home</div>
                  </div>
                </div>
                <div className="frame-327">
                  <div className="frame-328">
                    <div className="frame-319">
                      <img className="img-12" alt="Storage" src="/img/storage-5634434-1.png" />
                      <div className="text-wrapper-195">789</div>
                    </div>
                    <div className="frame-329">
                      <div className="text-wrapper-196">Deposit</div>
                    </div>
                  </div>
                  <div className="frame-291">
                    <div className="frame-330">
                      <img className="rectangle-26" alt="Rectangle" src="/img/rectangle-1.png" />
                      <div className="frame-331">
                        <div className="text-wrapper-181">John Watson</div>
                        <div className="group-24">
                          <div className="rectangle-27" />
                        </div>
                      </div>
                      <ChevronDownWrapper />
                    </div>
                  </div>
                </div>
                <div className="group-25">
                  <div className="frame-332">
                    <div className="text-wrapper-197">Rust</div>
                    <img className="up-arrow-4" alt="Up arrow" src="/img/up-arrow-2.png" />
                    <div className="text-wrapper-198">Oasis</div>
                  </div>
                </div>
                <img className="line-15" alt="Line" src="/img/line-2.png" />
                <div className="frame-333">
                  <img className="img-12" alt="Gameboy" src="/img/gameboy.png" />
                  <div className="text-wrapper-183">Games</div>
                  <ChevronDownWrapper />
                </div>
              </header>
              <div className="frame-334">
                <div className="overlap-40">
                  <div className="ellipse-59" />
                  <div className="ellipse-60" />
                  <div className="ellipse-61" />
                  <img className="vector-20" alt="Vector" src="/img/vector-14.png" />
                  <div className="frame-335">
                    <div className="frame-336">
                      <div className="overlap-group-40">
                        <img className="rectangle-28" alt="Rectangle" src="/img/rectangle-1-3.png" />
                        <div className="rectangle-29" />
                      </div>
                    </div>
                    <div className="frame-337">
                      <div className="frame-338">
                        <div className="text-wrapper-199">John Watson</div>
                        <p className="text-wrapper-200">Member since July 5 2022</p>
                      </div>
                      <div className="frame-339">
                        <div className="text-wrapper-201">Edit</div>
                        <img className="vuesax-bold-edit" alt="Vuesax bold edit" src="/img/vuesax-bold-edit-2-1.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-340">
                <div className="frame-316">
                  <div className="frame-341">
                    <img className="img-15" alt="Vuesax bold setting" src="/img/vuesax-bold-setting-2-8.png" />
                    <div className="text-wrapper-202">Settings</div>
                  </div>
                  <div className="frame-342">
                    <div className="img-15">
                      <div className="gamepad-2" />
                    </div>
                    <div className="text-wrapper-196">Game history</div>
                  </div>
                  <div className="frame-341">
                    <img className="img-15" alt="Vuesax bold receipt" src="/img/vuesax-bold-receipt-2.png" />
                    <div className="text-wrapper-202">Transactions</div>
                  </div>
                </div>
                <div className="frame-311">
                  <div className="frame-343">
                    <div className="text-wrapper-203">All</div>
                    <ChevronDownWrapper />
                  </div>
                  <div className="frame-343">
                    <div className="text-wrapper-194">From latest</div>
                    <ChevronDownWrapper />
                  </div>
                  <div className="frame-344">
                    <div className="frame-345">
                      <div className="text-wrapper-196">Roulette</div>
                    </div>
                    <div className="frame-346">
                      <div className="text-wrapper-204">Case</div>
                    </div>
                    <div className="frame-346">
                      <div className="text-wrapper-204">Case Battle</div>
                    </div>
                    <div className="frame-347">
                      <div className="text-wrapper-204">Upgrade</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-348">
                <div className="frame-349">
                  <div className="frame-350">
                    <div className="text-wrapper-205">Deposited</div>
                  </div>
                  <div className="frame-351">
                    <img className="img-14" alt="Storage" src="/img/storage-5634434-1-6.png" />
                    <div className="text-wrapper-206">789</div>
                  </div>
                  <div className="vuesax-bold-card">
                    <div className="card-tick-wrapper">
                      <div className="card-tick">
                        <img className="vector-21" alt="Vector" src="/img/chevron-down-1.png" />
                        <div className="overlap-group-41">
                          <img className="vector-22" alt="Vector" src="/img/vector-56.png" />
                          <div className="ellipse-62" />
                          <img className="vector-23" alt="Vector" src="/img/vector-7-1.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-349">
                  <div className="text-wrapper-207">Withdrawn</div>
                  <div className="frame-351">
                    <img className="img-12" alt="Storage" src="/img/storage-5634434-1-7.png" />
                    <div className="text-wrapper-208">789</div>
                  </div>
                  <div className="frame-352">
                    <div className="vector-wrapper">
                      <img className="vector-24" alt="Vector" src="/img/vector-9.png" />
                    </div>
                  </div>
                  <div className="ellipse-63" />
                </div>
                <div className="frame-349">
                  <div className="text-wrapper-205">Wagered</div>
                  <div className="frame-319">
                    <img className="img-12" alt="Storage" src="/img/storage-5634434-1-8.png" />
                    <div className="text-wrapper-206">35,5</div>
                  </div>
                  <div className="frame-353">
                    <div className="mask-group-wrapper">
                      <img className="mask-group" alt="Mask group" src="/img/mask-group-8.png" />
                    </div>
                  </div>
                </div>
                <div className="frame-349">
                  <div className="text-wrapper-205">Total</div>
                  <div className="frame-319">
                    <img className="img-12" alt="Storage" src="/img/storage-5634434-1-9.png" />
                    <p className="element-2">
                      <span className="text-wrapper-209">+</span>
                      <span className="text-wrapper-210">&nbsp;</span>
                      <span className="text-wrapper-209">789</span>
                    </p>
                  </div>
                  <div className="frame-354">
                    <div className="overlap-group-42">
                      <div className="rectangle-30" />
                      <img
                        className="vuesax-bold-activity"
                        alt="Vuesax bold activity"
                        src="/img/vuesax-bold-activity-10.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="side-bar-5">
                <img className="frame-355" alt="Frame" src="/img/frame-9-7.png" />
                <div className="frame-356">
                  <div className="text-wrapper-211">18+</div>
                </div>
              </div>
              <div className="chat-5">
                <div className="chat-header-5">
                  <div className="overlap-group-43">
                    <div className="ellipse-64" />
                    <div className="frame-357">
                      <img className="frame-358" alt="Frame" src="/img/frame-1-7.png" />
                      <div className="text-wrapper-181">Chat</div>
                    </div>
                  </div>
                  <div className="frame-359">
                    <div className="frame-319">
                      <div className="ellipse-55" />
                      <div className="text-wrapper-183">456</div>
                    </div>
                    <div className="frame-360">
                      <Gb className="gb-5" />
                      <div className="text-wrapper-183">ENG</div>
                      <ChevronDownWrapper />
                    </div>
                  </div>
                </div>
                <div className="overlap-41">
                  <div className="frame-361">
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-44">
                          <img className="star-25" alt="Star" src="/img/star-1-13.png" />
                          <img className="star-26" alt="Star" src="/img/star-2.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <p className="text-wrapper-213">anyone wanna join my 0.69 group battle for the jokes?</p>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-44">
                          <img className="star-25" alt="Star" src="/img/star-1-8.png" />
                          <img className="star-26" alt="Star" src="/img/star-2.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <div className="text-wrapper-213">gxsport call bots</div>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-44">
                          <img className="star-25" alt="Star" src="/img/star-1-9.png" />
                          <img className="star-26" alt="Star" src="/img/star-2.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <div className="text-wrapper-213">10$ jackpot?</div>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-44">
                          <img className="star-25" alt="Star" src="/img/star-1-10.png" />
                          <img className="star-26" alt="Star" src="/img/star-2-129.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <img className="element-3" alt="Element" src="/img/2024-07-07-14-19-47-1.png" />
                        <div className="text-wrapper-213">someone join the jackpot</div>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-44">
                          <img className="star-25" alt="Star" src="/img/star-1-2x.png" />
                          <img className="star-26" alt="Star" src="/img/star-2.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <div className="text-wrapper-213">admin</div>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-44">
                          <img className="star-25" alt="Star" src="/img/star-1-1.png" />
                          <img className="star-26" alt="Star" src="/img/star-2.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <div className="text-wrapper-214">CRAZY TERMINAL battle join</div>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-44">
                          <img className="star-25" alt="Star" src="/img/star-1-2-2x.png" />
                          <img className="star-26" alt="Star" src="/img/star-2.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <div className="text-wrapper-213">urob teraz 3way group</div>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-44">
                          <img className="star-25" alt="Star" src="/img/star-1-3.png" />
                          <img className="star-26" alt="Star" src="/img/star-2.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <div className="text-wrapper-213">someone join the jackpot</div>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-44">
                          <img className="star-25" alt="Star" src="/img/star-1-4-2x.png" />
                          <img className="star-26" alt="Star" src="/img/star-2.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <div className="text-wrapper-213">hola</div>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-44">
                          <img className="star-25" alt="Star" src="/img/star-1-5.png" />
                          <img className="star-26" alt="Star" src="/img/star-2.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <p className="text-wrapper-213">algun maquina que se apañe alguna gemita</p>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-44">
                          <img className="star-25" alt="Star" src="/img/star-1-6.png" />
                          <img className="star-26" alt="Star" src="/img/star-2.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <p className="text-wrapper-213">anyone wanna join my 0.69 group battle for the jokes?</p>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-44">
                          <img className="star-25" alt="Star" src="/img/star-1-13.png" />
                          <img className="star-26" alt="Star" src="/img/star-2-129.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <img className="element-3" alt="Element" src="/img/2024-07-07-14-19-47-1.png" />
                        <div className="text-wrapper-213">someone join the jackpot</div>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-45">
                          <img className="star-27" alt="Star" src="/img/star-1-4-2x.png" />
                          <img className="star-28" alt="Star" src="/img/chevron-down-1.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <div className="text-wrapper-213">admin</div>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-46">
                          <img className="star-27" alt="Star" src="/img/chevron-down-1.png" />
                          <img className="star-28" alt="Star" src="/img/chevron-down-1.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <div className="text-wrapper-214">CRAZY TERMINAL battle join</div>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-47">
                          <img className="star-27" alt="Star" src="/img/star-1-1.png" />
                          <img className="star-28" alt="Star" src="/img/chevron-down-1.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <div className="text-wrapper-213">urob teraz 3way group</div>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-48">
                          <img className="star-27" alt="Star" src="/img/chevron-down-1.png" />
                          <img className="star-28" alt="Star" src="/img/chevron-down-1.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <div className="text-wrapper-213">someone join the jackpot</div>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-49">
                          <img className="star-27" alt="Star" src="/img/star-1-4-2x.png" />
                          <img className="star-28" alt="Star" src="/img/chevron-down-1.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <p className="text-wrapper-213">anyone wanna join my 0.69 group battle for the jokes?</p>
                      </div>
                    </div>
                    <div className="frame-362">
                      <div className="group-26">
                        <div className="overlap-group-50">
                          <img className="star-27" alt="Star" src="/img/star-1-4-2x.png" />
                          <img className="star-28" alt="Star" src="/img/chevron-down-1.png" />
                        </div>
                      </div>
                      <div className="frame-363">
                        <div className="frame-315">
                          <div className="text-wrapper-212">ツ HXNRY 1X ツ</div>
                          <div className="text-wrapper-212">16:00 AM</div>
                        </div>
                        <p className="text-wrapper-213">anyone wanna join my 0.69 group battle for the jokes?</p>
                      </div>
                    </div>
                  </div>
                  <div className="frame-364">
                    <div className="frame-365">
                      <div className="text-wrapper-215">Your message...</div>
                      <img className="smile-5" alt="Smile" src="/img/smile-7.png" />
                      <img className="frame-366" alt="Frame" src="/img/frame-37.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="rectangle-31" />
              <div className="frame-367">
                <div className="frame-368">
                  <div className="group-27">
                    <div className="frame-369">
                      <div className="frame-370">
                        <div className="frame-371">
                          <div className="frame-291">
                            <img className="group-23" alt="Frame" src="/img/frame-64-4.png" />
                            <div className="text-wrapper-216">Roulette</div>
                          </div>
                          <div className="frame-372">
                            <div className="group-23">
                              <div className="overlap-group-51">
                                <img className="star-23" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-24" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-216">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-373">
                          <div className="text-wrapper-203">20 sec ago</div>
                        </div>
                        <div className="frame-374">
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1.png" />
                            <div className="text-wrapper-195">789</div>
                          </div>
                          <div className="text-wrapper-195">14.5x</div>
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1-11.png" />
                            <div className="text-wrapper-195">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-375">
                        <div className="frame-371">
                          <div className="frame-376">
                            <img className="group-23" alt="Frame" src="/img/frame-65-21.png" />
                            <div className="text-wrapper-217">Case Battle</div>
                          </div>
                          <div className="frame-372">
                            <div className="group-23">
                              <div className="overlap-group-52">
                                <img className="star-23" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-24" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-216">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-373">
                          <div className="text-wrapper-203">20 sec ago</div>
                        </div>
                        <div className="frame-374">
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1.png" />
                            <div className="text-wrapper-195">789</div>
                          </div>
                          <div className="text-wrapper-195">14.5x</div>
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1-13.png" />
                            <div className="text-wrapper-195">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-377">
                          <img className="image-18" alt="Image" src="/img/image-3.png" />
                          <img className="image-18" alt="Image" src="/img/image-16.png" />
                          <img className="image-18" alt="Image" src="/img/image-3.png" />
                        </div>
                      </div>
                      <div className="frame-370">
                        <div className="frame-371">
                          <div className="frame-376">
                            <img className="group-23" alt="Frame" src="/img/frame-95.png" />
                            <div className="text-wrapper-216">Case</div>
                          </div>
                          <div className="frame-372">
                            <div className="group-23">
                              <div className="overlap-group-53">
                                <img className="star-23" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-24" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-216">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-373">
                          <div className="text-wrapper-203">20 sec ago</div>
                        </div>
                        <div className="frame-374">
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1.png" />
                            <div className="text-wrapper-195">789</div>
                          </div>
                          <div className="text-wrapper-195">14.5x</div>
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1-11.png" />
                            <div className="text-wrapper-195">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-375">
                        <div className="frame-371">
                          <div className="frame-376">
                            <img className="group-23" alt="Frame" src="/img/frame-64.png" />
                            <div className="text-wrapper-218">Upgrade</div>
                          </div>
                          <div className="frame-372">
                            <div className="group-23">
                              <div className="overlap-group-54">
                                <img className="star-23" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-24" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-216">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-373">
                          <div className="text-wrapper-203">20 sec ago</div>
                        </div>
                        <div className="frame-374">
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1.png" />
                            <div className="text-wrapper-195">789</div>
                          </div>
                          <div className="text-wrapper-195">14.5x</div>
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1-13.png" />
                            <div className="text-wrapper-195">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-377">
                          <img className="image-18" alt="Image" src="/img/image-3.png" />
                          <img className="image-18" alt="Image" src="/img/image-16.png" />
                          <img className="image-18" alt="Image" src="/img/image-3.png" />
                        </div>
                      </div>
                      <div className="frame-370">
                        <div className="frame-371">
                          <div className="frame-291">
                            <img className="group-23" alt="Frame" src="/img/frame-64-4.png" />
                            <div className="text-wrapper-216">Roulette</div>
                          </div>
                          <div className="frame-372">
                            <div className="group-23">
                              <div className="overlap-group-55">
                                <img className="star-23" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-24" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-216">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-373">
                          <div className="text-wrapper-203">20 sec ago</div>
                        </div>
                        <div className="frame-374">
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1.png" />
                            <div className="text-wrapper-195">789</div>
                          </div>
                          <div className="text-wrapper-195">14.5x</div>
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1-11.png" />
                            <div className="text-wrapper-195">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-375">
                        <div className="frame-371">
                          <div className="frame-376">
                            <img className="group-23" alt="Frame" src="/img/frame-65-21.png" />
                            <div className="text-wrapper-217">Case Battle</div>
                          </div>
                          <div className="frame-372">
                            <div className="group-23">
                              <div className="overlap-group-56">
                                <img className="star-23" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-24" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-216">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-373">
                          <div className="text-wrapper-203">20 sec ago</div>
                        </div>
                        <div className="frame-374">
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1.png" />
                            <div className="text-wrapper-195">789</div>
                          </div>
                          <div className="text-wrapper-195">14.5x</div>
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1-13.png" />
                            <div className="text-wrapper-195">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-377">
                          <img className="image-18" alt="Image" src="/img/image-3.png" />
                          <img className="image-18" alt="Image" src="/img/image-16.png" />
                          <img className="image-18" alt="Image" src="/img/image-3.png" />
                        </div>
                      </div>
                      <div className="frame-370">
                        <div className="frame-371">
                          <div className="frame-376">
                            <img className="group-23" alt="Frame" src="/img/frame-95.png" />
                            <div className="text-wrapper-216">Case</div>
                          </div>
                          <div className="frame-372">
                            <div className="group-23">
                              <div className="overlap-group-57">
                                <img className="star-23" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-24" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-216">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-373">
                          <div className="text-wrapper-203">20 sec ago</div>
                        </div>
                        <div className="frame-374">
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1.png" />
                            <div className="text-wrapper-195">789</div>
                          </div>
                          <div className="text-wrapper-195">14.5x</div>
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1-11.png" />
                            <div className="text-wrapper-195">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-375">
                        <div className="frame-371">
                          <div className="frame-376">
                            <img className="group-23" alt="Frame" src="/img/frame-64.png" />
                            <div className="text-wrapper-218">Upgrade</div>
                          </div>
                          <div className="frame-372">
                            <div className="group-23">
                              <div className="overlap-group-58">
                                <img className="star-23" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-24" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-216">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-373">
                          <div className="text-wrapper-203">20 sec ago</div>
                        </div>
                        <div className="frame-374">
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1.png" />
                            <div className="text-wrapper-195">789</div>
                          </div>
                          <div className="text-wrapper-195">14.5x</div>
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1-13.png" />
                            <div className="text-wrapper-195">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-377">
                          <img className="image-18" alt="Image" src="/img/image-3.png" />
                          <img className="image-18" alt="Image" src="/img/image-16.png" />
                          <img className="image-18" alt="Image" src="/img/image-3.png" />
                        </div>
                      </div>
                      <div className="frame-370">
                        <div className="frame-371">
                          <div className="frame-291">
                            <img className="group-23" alt="Frame" src="/img/frame-64-4.png" />
                            <div className="text-wrapper-216">Roulette</div>
                          </div>
                          <div className="frame-372">
                            <div className="group-23">
                              <div className="overlap-group-59">
                                <img className="star-23" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-24" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-216">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-373">
                          <div className="text-wrapper-203">20 sec ago</div>
                        </div>
                        <div className="frame-374">
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1.png" />
                            <div className="text-wrapper-195">789</div>
                          </div>
                          <div className="text-wrapper-195">14.5x</div>
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1-11.png" />
                            <div className="text-wrapper-195">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-375">
                        <div className="frame-371">
                          <div className="frame-376">
                            <img className="group-23" alt="Frame" src="/img/frame-65-21.png" />
                            <div className="text-wrapper-217">Case Battle</div>
                          </div>
                          <div className="frame-372">
                            <div className="group-23">
                              <div className="overlap-group-60">
                                <img className="star-23" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-24" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-216">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-373">
                          <div className="text-wrapper-203">20 sec ago</div>
                        </div>
                        <div className="frame-374">
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1.png" />
                            <div className="text-wrapper-195">789</div>
                          </div>
                          <div className="text-wrapper-195">14.5x</div>
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1-13.png" />
                            <div className="text-wrapper-195">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-377">
                          <img className="image-18" alt="Image" src="/img/image-3.png" />
                          <img className="image-18" alt="Image" src="/img/image-16.png" />
                          <img className="image-18" alt="Image" src="/img/image-3.png" />
                        </div>
                      </div>
                      <div className="frame-370">
                        <div className="frame-371">
                          <div className="frame-376">
                            <img className="group-23" alt="Frame" src="/img/frame-95.png" />
                            <div className="text-wrapper-216">Case</div>
                          </div>
                          <div className="frame-372">
                            <div className="group-23">
                              <div className="overlap-group-61">
                                <img className="star-23" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-24" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-216">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-373">
                          <div className="text-wrapper-203">20 sec ago</div>
                        </div>
                        <div className="frame-374">
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1.png" />
                            <div className="text-wrapper-195">789</div>
                          </div>
                          <div className="text-wrapper-195">14.5x</div>
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1-11.png" />
                            <div className="text-wrapper-195">+ 789</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-375">
                        <div className="frame-371">
                          <div className="frame-376">
                            <img className="group-23" alt="Frame" src="/img/frame-64.png" />
                            <div className="text-wrapper-218">Upgrade</div>
                          </div>
                          <div className="frame-372">
                            <div className="group-23">
                              <div className="overlap-group-62">
                                <img className="star-23" alt="Star" src="/img/star-1-7.png" />
                                <img className="star-24" alt="Star" src="/img/chevron-down-1.png" />
                              </div>
                            </div>
                            <div className="text-wrapper-216">ツ HXNRY 1X ツ</div>
                          </div>
                        </div>
                        <div className="frame-373">
                          <div className="text-wrapper-203">20 sec ago</div>
                        </div>
                        <div className="frame-374">
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1.png" />
                            <div className="text-wrapper-195">789</div>
                          </div>
                          <div className="text-wrapper-195">14.5x</div>
                          <div className="frame-319">
                            <img className="img-12" alt="Storage" src="/img/storage-5634434-1-13.png" />
                            <div className="text-wrapper-195">+ 0</div>
                          </div>
                        </div>
                        <div className="frame-377">
                          <img className="image-18" alt="Image" src="/img/image-3.png" />
                          <img className="image-18" alt="Image" src="/img/image-16.png" />
                          <img className="image-18" alt="Image" src="/img/image-3.png" />
                        </div>
                      </div>
                    </div>
                    <div className="group-28">
                      <div className="text-wrapper-219">Game</div>
                      <div className="text-wrapper-220">ITEM</div>
                      <div className="text-wrapper-221">Time</div>
                      <div className="text-wrapper-222">Bet</div>
                      <div className="text-wrapper-223">Multiplayer</div>
                      <div className="text-wrapper-224">Result</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rectangle-31" />
            </>
          )}

          <div
            className="rectangle-32"
            style={{
              WebkitBackdropFilter:
                screenWidth < 900
                  ? "blur(25px) brightness(100%)"
                  : screenWidth >= 900
                  ? "blur(15px) brightness(100%)"
                  : undefined,
              backdropFilter:
                screenWidth < 900
                  ? "blur(25px) brightness(100%)"
                  : screenWidth >= 900
                  ? "blur(15px) brightness(100%)"
                  : undefined,
              backgroundColor: screenWidth < 900 ? "#000000b2" : screenWidth >= 900 ? "#000000a6" : undefined,
              height: screenWidth < 900 ? "1011px" : screenWidth >= 900 ? "1083px" : undefined,
              width: screenWidth < 900 ? "376px" : screenWidth >= 900 ? "900px" : undefined,
            }}
          />
          <div
            className="group-29"
            style={{
              height: screenWidth < 900 ? "666px" : screenWidth >= 900 ? "502px" : undefined,
              left: screenWidth < 900 ? "21px" : screenWidth >= 900 ? "583px" : undefined,
              top: screenWidth < 900 ? "64px" : screenWidth >= 900 ? "289px" : undefined,
              width: screenWidth < 900 ? "342px" : screenWidth >= 900 ? "754px" : undefined,
            }}
          >
            <div
              className="overlap-42"
              style={{
                height: screenWidth < 900 ? "666px" : screenWidth >= 900 ? "502px" : undefined,
              }}
            >
              <div
                className="frame-378"
                style={{
                  gap: screenWidth < 900 ? "12px" : screenWidth >= 900 ? "20px" : undefined,
                  top: screenWidth < 900 ? "8px" : screenWidth >= 900 ? "10px" : undefined,
                }}
              >
                <div
                  className="frame-379"
                  style={{
                    borderRadius: screenWidth < 900 ? "16px" : screenWidth >= 900 ? "20px" : undefined,
                    padding: screenWidth < 900 ? "24px 20px 20px" : screenWidth >= 900 ? "24px" : undefined,
                    width: screenWidth < 900 ? "334px" : screenWidth >= 900 ? "744px" : undefined,
                  }}
                >
                  <div
                    className="frame-380"
                    style={{
                      alignItems: screenWidth < 900 ? "flex-start" : screenWidth >= 900 ? "center" : undefined,
                      flexDirection: screenWidth < 900 ? "column" : undefined,
                      gap: screenWidth < 900 ? "20px" : undefined,
                      justifyContent:
                        screenWidth < 900 ? "flex-end" : screenWidth >= 900 ? "space-between" : undefined,
                    }}
                  >
                    {screenWidth < 900 && (
                      <>
                        <div className="frame-381">
                          <div className="frame-291">
                            <VuesaxBoldWallet
                              className="vuesax-bold-wallet-7"
                              vuesaxBoldWallet="/img/vuesax-bold-wallet-2-15.png"
                            />
                            <div className="text-wrapper-225">Withdrawal</div>
                          </div>
                        </div>
                        <div className="frame-338">
                          <div className="frame-382">
                            <div className="frame-383">
                              <div className="frame-291">
                                <img
                                  className="vuesax-bold-gift"
                                  alt="Vuesax bold gift"
                                  src="/img/vuesax-bold-gift-3.png"
                                />
                                <p className="element-4">
                                  <span className="text-wrapper-226">5640 </span>
                                  <span className="text-wrapper-227">|</span>
                                </p>
                              </div>
                              <div className="frame-384">
                                <div className="text-wrapper-228">Activate</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-385">
                          <div className="frame-386">
                            <div className="text-wrapper-205">All</div>
                          </div>
                          <div className="frame-387">
                            <div className="text-wrapper-229">Crypto</div>
                          </div>
                          <div className="frame-387">
                            <div className="text-wrapper-190">Skins</div>
                          </div>
                          <div className="frame-388">
                            <div className="text-wrapper-230">Money</div>
                          </div>
                        </div>
                      </>
                    )}

                    {screenWidth >= 900 && (
                      <>
                        <div className="frame-389">
                          <div className="frame-381">
                            <div className="frame-291">
                              <VuesaxBoldWallet
                                className="vuesax-bold-wallet-8"
                                vuesaxBoldWallet="/img/vuesax-bold-wallet-2-14.png"
                              />
                              <div className="text-wrapper-231">Withdrawal</div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-390">
                          <div className="frame-382">
                            <div className="frame-391">
                              <div className="frame-291">
                                <img
                                  className="vuesax-bold-gift"
                                  alt="Vuesax bold gift"
                                  src="/img/vuesax-bold-gift-3.png"
                                />
                                <p className="element-4">
                                  <span className="text-wrapper-226">5640 </span>
                                  <span className="text-wrapper-227">|</span>
                                </p>
                              </div>
                              <div className="frame-384">
                                <div className="text-wrapper-232">Activate</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="frame-392"
                  style={{
                    alignSelf: screenWidth < 900 ? "stretch" : undefined,
                    borderRadius: screenWidth < 900 ? "16px" : screenWidth >= 900 ? "20px" : undefined,
                    display: screenWidth < 900 ? "flex" : screenWidth >= 900 ? "inline-flex" : undefined,
                    padding: screenWidth < 900 ? "20px" : screenWidth >= 900 ? "24px" : undefined,
                    width: screenWidth < 900 ? "100%" : undefined,
                  }}
                >
                  <div
                    className="frame-393"
                    style={{
                      gap: screenWidth < 900 ? "8px" : screenWidth >= 900 ? "12px" : undefined,
                    }}
                  >
                    {screenWidth < 900 && (
                      <>
                        <div className="frame-382">
                          <div className="frame-394">
                            <img className="tether" alt="Tether" src="/img/tether-3-1-3.png" />
                            <div className="frame-395">
                              <p className="USDT">
                                <span className="text-wrapper-233">USDT </span>
                                <span className="text-wrapper-234">TRC20</span>
                              </p>
                              <div className="text-wrapper-235">External</div>
                            </div>
                            <div className="ellipse-65" />
                          </div>
                          <div className="frame-394">
                            <img className="tether-2" alt="Tether" src="/img/tether-3-1-4.png" />
                            <div className="frame-395">
                              <p className="USDT">
                                <span className="text-wrapper-233">USDT </span>
                                <span className="text-wrapper-234">TRC20</span>
                              </p>
                              <div className="text-wrapper-235">External</div>
                            </div>
                            <div className="ellipse-66" />
                          </div>
                        </div>
                        <div className="frame-382">
                          <div className="frame-396">
                            <img className="tether-3" alt="Tether" src="/img/tether-3-1-8.png" />
                            <div className="frame-395">
                              <p className="USDT-trc">
                                <span className="text-wrapper-233">USDT </span>
                                <span className="text-wrapper-234">TRC20</span>
                              </p>
                              <div className="text-wrapper-235">External</div>
                            </div>
                            <div className="ellipse-67" />
                          </div>
                          <div className="frame-394">
                            <img className="tether" alt="Tether" src="/img/tether-3-1-3.png" />
                            <div className="frame-395">
                              <p className="USDT">
                                <span className="text-wrapper-233">USDT </span>
                                <span className="text-wrapper-234">TRC20</span>
                              </p>
                              <div className="text-wrapper-235">External</div>
                            </div>
                            <div className="ellipse-65" />
                          </div>
                        </div>
                        <div className="frame-382">
                          <div className="frame-394">
                            <img className="tether" alt="Tether" src="/img/tether-3-1-3.png" />
                            <div className="frame-395">
                              <p className="USDT">
                                <span className="text-wrapper-233">USDT </span>
                                <span className="text-wrapper-234">TRC20</span>
                              </p>
                              <div className="text-wrapper-235">External</div>
                            </div>
                            <div className="ellipse-65" />
                          </div>
                          <div className="frame-394">
                            <img className="tether-2" alt="Tether" src="/img/tether-3-1-4.png" />
                            <div className="frame-395">
                              <p className="USDT">
                                <span className="text-wrapper-233">USDT </span>
                                <span className="text-wrapper-234">TRC20</span>
                              </p>
                              <div className="text-wrapper-235">External</div>
                            </div>
                            <div className="ellipse-66" />
                          </div>
                        </div>
                      </>
                    )}

                    {screenWidth >= 900 && (
                      <>
                        <div className="frame-397">
                          <div className="frame-398">
                            <img className="tether-4" alt="Tether" src="/img/tether-3-1-2.png" />
                            <div className="frame-395">
                              <p className="USDT-2">
                                <span className="text-wrapper-233">USDT </span>
                                <span className="text-wrapper-234">TRC20</span>
                              </p>
                              <div className="text-wrapper-236">External</div>
                            </div>
                            <div className="ellipse-65" />
                          </div>
                          <div className="frame-398">
                            <img className="tether-5" alt="Tether" src="/img/tether-3-1.png" />
                            <div className="frame-395">
                              <p className="USDT-2">
                                <span className="text-wrapper-233">USDT </span>
                                <span className="text-wrapper-234">TRC20</span>
                              </p>
                              <div className="text-wrapper-236">External</div>
                            </div>
                            <div className="ellipse-66" />
                          </div>
                          <div className="frame-398">
                            <img className="tether-6" alt="Tether" src="/img/tether-3-1-1.png" />
                            <div className="frame-395">
                              <p className="USDT-2">
                                <span className="text-wrapper-233">USDT </span>
                                <span className="text-wrapper-234">TRC20</span>
                              </p>
                              <div className="text-wrapper-237">External</div>
                            </div>
                            <div className="ellipse-68" />
                            <div className="ellipse-69" />
                          </div>
                          <div className="frame-398">
                            <img className="tether-5" alt="Tether" src="/img/tether-3-1.png" />
                            <div className="frame-395">
                              <p className="USDT-2">
                                <span className="text-wrapper-233">USDT </span>
                                <span className="text-wrapper-234">TRC20</span>
                              </p>
                              <div className="text-wrapper-236">External</div>
                            </div>
                            <div className="ellipse-66" />
                          </div>
                          <div className="frame-399">
                            <img className="tether-7" alt="Tether" src="/img/chevron-down-1.png" />
                            <div className="frame-400">
                              <p className="USDT-2">
                                <span className="text-wrapper-233">USDT </span>
                                <span className="text-wrapper-234">TRC20</span>
                              </p>
                              <div className="text-wrapper-237">External</div>
                            </div>
                            <div className="ellipse-70" />
                            <div className="ellipse-71" />
                          </div>
                        </div>
                        <div className="frame-397">
                          <div className="frame-398">
                            <img className="tether-6" alt="Tether" src="/img/tether-3-1-1.png" />
                            <div className="frame-395">
                              <p className="USDT-2">
                                <span className="text-wrapper-233">USDT </span>
                                <span className="text-wrapper-234">TRC20</span>
                              </p>
                              <div className="text-wrapper-236">External</div>
                            </div>
                            <div className="ellipse-67" />
                          </div>
                          <div className="frame-398">
                            <img className="tether-4" alt="Tether" src="/img/tether-3-1-2.png" />
                            <div className="frame-395">
                              <p className="USDT-2">
                                <span className="text-wrapper-233">USDT </span>
                                <span className="text-wrapper-234">TRC20</span>
                              </p>
                              <div className="text-wrapper-236">External</div>
                            </div>
                            <div className="ellipse-65" />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="x-wrapper"
                style={{
                  left: screenWidth < 900 ? "310px" : screenWidth >= 900 ? "722px" : undefined,
                }}
              >
                <X2 className="x-2-instance" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
