import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import { VuesaxBoldWallet } from "../../components/VuesaxBoldWallet";
import "./style.css";

export const OpenCase = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="open-case">
      <div
        className="open-cases-2"
        style={{
          height: screenWidth < 900 ? "1255px" : screenWidth >= 900 ? "1150px" : undefined,
          overflow: screenWidth >= 900 ? "hidden" : undefined,
          width: screenWidth < 900 ? "375px" : screenWidth >= 900 ? "900px" : undefined,
        }}
      >
        {screenWidth >= 900 && (
          <div className="side-bar-3">
            <img className="frame-143" alt="Frame" src="/img/frame-9-7.png" />
            <div className="frame-144">
              <div className="text-wrapper-94">18+</div>
            </div>
          </div>
        )}

        <div
          className="overlap-18"
          style={{
            height: screenWidth >= 900 ? "1078px" : screenWidth < 900 ? "323px" : undefined,
            width: screenWidth >= 900 ? "900px" : screenWidth < 900 ? "375px" : undefined,
          }}
        >
          {screenWidth >= 900 && <img className="vector-9" alt="Vector" src="/img/vector-77.png" />}

          <div
            className="ellipse-34"
            style={{
              borderRadius: screenWidth >= 900 ? "205px" : screenWidth < 900 ? "168px/49px" : undefined,
              height: screenWidth >= 900 ? "410px" : screenWidth < 900 ? "98px" : undefined,
              left: screenWidth >= 900 ? "867px" : screenWidth < 900 ? "19px" : undefined,
              top: screenWidth >= 900 ? "23px" : screenWidth < 900 ? "87px" : undefined,
              width: screenWidth >= 900 ? "410px" : screenWidth < 900 ? "336px" : undefined,
            }}
          />
          {screenWidth >= 900 && (
            <>
              <div className="frame-145">
                <div className="frame-146">
                  <div className="frame-147">
                    <div className="frame-148">
                      <div className="text-wrapper-95">Case Name</div>
                      <div className="frame-149">
                        <div className="image-wrapper">
                          <img className="image-6" alt="Image" src="/img/image-9.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-150">
                    <div className="frame-151">
                      <div className="frame-152">
                        <div className="text-wrapper-96">X1</div>
                        <img className="vector-10" alt="Vector" src="/img/vector-3-1.png" />
                        <div className="text-wrapper-97">X2</div>
                        <img className="vector-10" alt="Vector" src="/img/vector-3-1.png" />
                        <div className="text-wrapper-97">X3</div>
                        <img className="vector-10" alt="Vector" src="/img/vector-3-1.png" />
                        <div className="text-wrapper-97">X4</div>
                        <img className="line-6" alt="Line" src="/img/line-1-13.png" />
                      </div>
                      <div className="frame-153">
                        <div className="text-wrapper-98">Open for</div>
                        <div className="frame-154">
                          <img className="img-7" alt="Storage" src="/img/storage-5634434-1-120.png" />
                          <div className="text-wrapper-99">5.5</div>
                        </div>
                      </div>
                      <div className="frame-155">
                        <div className="text-wrapper-100">Fast open</div>
                        <img className="img-7" alt="Vuesax bold flash" src="/img/vuesax-bold-flash.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-156">
                  <div className="frame-157">
                    <div className="text-wrapper-101">Case contents</div>
                    <p className="text-wrapper-102">All items that may fall out of your case</p>
                  </div>
                  <div className="frame-158">
                    <div className="frame-159">
                      <div className="frame-160">
                        <div className="overlap-group-20">
                          <div className="ellipse-35" />
                          <img className="image-7" alt="Image" src="/img/image-17.png" />
                          <div className="frame-161">
                            <div className="text-wrapper-103">Resource</div>
                            <div className="text-wrapper-104">Cloth</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-162">
                        <div className="overlap-group-20">
                          <div className="ellipse-36" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Clothing</div>
                            <div className="text-wrapper-105">Snowcamo Jacket</div>
                          </div>
                          <img className="image-7" alt="Image" src="/img/image-18.png" />
                        </div>
                      </div>
                      <div className="frame-164">
                        <div className="overlap-19">
                          <div className="ellipse-37" />
                          <img className="vector-11" alt="Vector" src="/img/vector-78.png" />
                          <img className="image-8" alt="Image" src="/img/image-19.png" />
                          <div className="frame-165">
                            <div className="text-wrapper-106">Weapon</div>
                            <div className="text-wrapper-105">Tempered AK47</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-166">
                        <div className="overlap-group-20">
                          <div className="ellipse-38" />
                          <img className="image-9" alt="Image" src="/img/image-4.png" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Misc</div>
                            <div className="text-wrapper-105">High Quality Bag</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-160">
                        <div className="overlap-group-20">
                          <div className="ellipse-35" />
                          <img className="image-7" alt="Image" src="/img/image-17.png" />
                          <div className="frame-161">
                            <div className="text-wrapper-103">Resource</div>
                            <div className="text-wrapper-104">Cloth</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-162">
                        <div className="overlap-group-20">
                          <div className="ellipse-36" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Clothing</div>
                            <div className="text-wrapper-105">Snowcamo Jacket</div>
                          </div>
                          <img className="image-7" alt="Image" src="/img/image-18.png" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-159">
                      <div className="frame-162">
                        <div className="overlap-group-20">
                          <div className="ellipse-36" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Clothing</div>
                            <div className="text-wrapper-105">Snowcamo Jacket</div>
                          </div>
                          <img className="image-7" alt="Image" src="/img/image-18.png" />
                        </div>
                      </div>
                      <div className="frame-160">
                        <div className="overlap-group-20">
                          <div className="ellipse-35" />
                          <img className="image-7" alt="Image" src="/img/image-17.png" />
                          <div className="frame-161">
                            <div className="text-wrapper-103">Resource</div>
                            <div className="text-wrapper-104">Cloth</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-162">
                        <div className="overlap-group-20">
                          <div className="ellipse-36" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Clothing</div>
                            <div className="text-wrapper-105">Snowcamo Jacket</div>
                          </div>
                          <img className="image-7" alt="Image" src="/img/image-18.png" />
                        </div>
                      </div>
                      <div className="frame-167">
                        <div className="overlap-group-20">
                          <div className="ellipse-39" />
                          <img className="image-9" alt="Image" src="/img/image-19.png" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Weapon</div>
                            <div className="text-wrapper-105">Tempered AK47</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-166">
                        <div className="overlap-group-20">
                          <div className="ellipse-38" />
                          <img className="image-9" alt="Image" src="/img/image-4.png" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Misc</div>
                            <div className="text-wrapper-105">High Quality Bag</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-160">
                        <div className="overlap-group-20">
                          <div className="ellipse-35" />
                          <img className="image-7" alt="Image" src="/img/image-17.png" />
                          <div className="frame-161">
                            <div className="text-wrapper-103">Resource</div>
                            <div className="text-wrapper-104">Cloth</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-168">
                      <div className="frame-162">
                        <div className="overlap-group-20">
                          <div className="ellipse-36" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Clothing</div>
                            <div className="text-wrapper-105">Snowcamo Jacket</div>
                          </div>
                          <img className="image-7" alt="Image" src="/img/image-18.png" />
                        </div>
                      </div>
                      <div className="frame-167">
                        <div className="overlap-group-20">
                          <div className="ellipse-39" />
                          <img className="image-9" alt="Image" src="/img/image-19.png" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Weapon</div>
                            <div className="text-wrapper-105">Tempered AK47</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-166">
                        <div className="overlap-group-20">
                          <div className="ellipse-38" />
                          <img className="image-9" alt="Image" src="/img/image-4.png" />
                          <div className="frame-163">
                            <div className="text-wrapper-103">Misc</div>
                            <div className="text-wrapper-105">High Quality Bag</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-160">
                        <div className="overlap-group-20">
                          <div className="ellipse-35" />
                          <img className="image-7" alt="Image" src="/img/image-17.png" />
                          <div className="frame-161">
                            <div className="text-wrapper-103">Resource</div>
                            <div className="text-wrapper-104">Cloth</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <header className="header-3">
                <div className="overlap-20">
                  <div className="ellipse-40" />
                  <img className="line-7" alt="Line" src="/img/line-1.png" />
                </div>
                <div className="frame-169">
                  <div className="row-3">
                    <img className="img-7" alt="Home" src="/img/home-06.png" />
                    <div className="text-wrapper-107">Home</div>
                  </div>
                </div>
                <div className="frame-170">
                  <div className="frame-171">
                    <div className="frame-154">
                      <img className="img-7" alt="Storage" src="/img/storage-5634434-1.png" />
                      <div className="text-wrapper-108">789</div>
                    </div>
                    <div className="frame-154">
                      <div className="frame-172">
                        <div className="text-wrapper-109">Deposit</div>
                      </div>
                      <div className="frame-173">
                        <VuesaxBoldWallet
                          className="vuesax-bold-wallet-3"
                          vuesaxBoldWallet="/img/vuesax-bold-wallet-2.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="frame-174">
                    <div className="frame-175">
                      <img className="rectangle-13" alt="Rectangle" src="/img/rectangle-1.png" />
                      <div className="frame-176">
                        <div className="text-wrapper-110">John Watson</div>
                        <div className="group-10">
                          <div className="rectangle-14" />
                        </div>
                      </div>
                      <ChevronDownWrapper />
                    </div>
                  </div>
                </div>
                <img className="line-8" alt="Line" src="/img/line-2.png" />
                <div className="frame-177">
                  <img className="img-7" alt="Gameboy" src="/img/gameboy.png" />
                  <div className="text-wrapper-111">Games</div>
                  <ChevronDownWrapper />
                </div>
                <img className="logo-4" alt="Logo" src="/img/logo-35.png" />
              </header>
            </>
          )}

          {screenWidth < 900 && (
            <>
              <div className="text-wrapper-112">Case Name</div>
              <div className="frame-178">
                <div className="overlap-group-21">
                  <img className="image-10" alt="Image" src="/img/image-9.png" />
                </div>
              </div>
              <div className="frame-179">
                <img
                  className="vuesax-linear-arrow-3"
                  alt="Vuesax linear arrow"
                  src="/img/vuesax-linear-arrow-left.png"
                />
              </div>
              <div className="frame-180">
                <div className="element-system-bar-2">
                  <div className="group-11">
                    <div className="battery-2">
                      <div className="overlap-group-22">
                        <div className="capacity-2" />
                      </div>
                      <img className="cap-2" alt="Cap" src="/img/cap-1.png" />
                    </div>
                    <img className="wifi-2" alt="Wifi" src="/img/wifi-1.png" />
                    <img
                      className="cellular-connection-2"
                      alt="Cellular connection"
                      src="/img/cellular-connection-3.png"
                    />
                  </div>
                  <div className="time-wrapper">
                    <p className="time-2">
                      <span className="text-wrapper-113">9:4</span>
                      <span className="text-wrapper-114">1</span>
                    </p>
                  </div>
                  <div className="floating-island-2" />
                </div>
                <div className="frame-181">
                  <img className="logo-5" alt="Logo" src="/img/logo-38.png" />
                  <div className="frame-182">
                    <div className="frame-154">
                      <img className="img-8" alt="Storage" src="/img/storage-5634434-1-2.png" />
                      <div className="text-wrapper-115">789</div>
                    </div>
                    <div className="frame-183">
                      <div className="frame-184">
                        <div className="frame-185">
                          <div className="text-wrapper-116">Deposit</div>
                        </div>
                      </div>
                      <div className="frame-186">
                        <VuesaxBoldWallet
                          className="vuesax-bold-wallet-4"
                          vuesaxBoldWallet="/img/vuesax-bold-wallet-2-18.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {screenWidth >= 900 && (
          <>
            <div className="frame-187">
              <img
                className="vuesax-linear-arrow-4"
                alt="Vuesax linear arrow"
                src="/img/vuesax-linear-arrow-left.png"
              />
              <div className="text-wrapper-117">Go back</div>
            </div>
            <div className="chat-3">
              <div className="chat-header-3">
                <div className="overlap-group-23">
                  <div className="ellipse-41" />
                  <div className="frame-188">
                    <img className="frame-189" alt="Frame" src="/img/frame-1-7.png" />
                    <div className="text-wrapper-110">Chat</div>
                  </div>
                </div>
                <div className="frame-190">
                  <div className="frame-154">
                    <div className="ellipse-42" />
                    <div className="text-wrapper-111">456</div>
                  </div>
                  <div className="frame-191">
                    <Gb className="gb-2" />
                    <div className="text-wrapper-111">ENG</div>
                    <ChevronDownWrapper />
                  </div>
                </div>
              </div>
              <div className="overlap-21">
                <div className="frame-192">
                  <div className="frame-193">
                    <div className="frame-194">
                      <div className="div-6">
                        <div className="overlap-22">
                          <img className="star-11" alt="Star" src="/img/star-1-2x.png" />
                          <img className="star-12" alt="Star" src="/img/star-2.png" />
                          <div className="frame-195">
                            <div className="overlap-group-24">
                              <div className="text-wrapper-118">10</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="text-wrapper-119">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-119">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-120">admin</div>
                    </div>
                  </div>
                  <div className="frame-193">
                    <div className="frame-194">
                      <div className="div-6">
                        <div className="group-12">
                          <div className="overlap-group-25">
                            <img className="star-13" alt="Star" src="/img/star-1-1.png" />
                            <img className="star-14" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-198">
                          <div className="overlap-23">
                            <div className="text-wrapper-121">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="text-wrapper-119">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-119">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-122">CRAZY TERMINAL battle join</div>
                    </div>
                  </div>
                  <div className="frame-193">
                    <div className="frame-194">
                      <div className="div-6">
                        <div className="group-12">
                          <div className="overlap-group-25">
                            <img className="star-13" alt="Star" src="/img/star-1-2-2x.png" />
                            <img className="star-14" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-198">
                          <div className="overlap-24">
                            <div className="text-wrapper-123">1</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="text-wrapper-119">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-119">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-120">urob teraz 3way group</div>
                    </div>
                  </div>
                  <div className="frame-193">
                    <div className="frame-194">
                      <div className="div-6">
                        <div className="group-12">
                          <div className="overlap-group-25">
                            <img className="star-13" alt="Star" src="/img/star-1-3.png" />
                            <img className="star-14" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-198">
                          <div className="overlap-25">
                            <div className="text-wrapper-121">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="text-wrapper-119">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-119">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-120">someone join the jackpot</div>
                    </div>
                  </div>
                  <div className="frame-193">
                    <div className="frame-194">
                      <div className="div-6">
                        <div className="group-12">
                          <div className="overlap-group-25">
                            <img className="star-13" alt="Star" src="/img/star-1-4-2x.png" />
                            <img className="star-14" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-198">
                          <div className="overlap-24">
                            <div className="text-wrapper-123">1</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="text-wrapper-119">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-119">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-120">hola</div>
                    </div>
                  </div>
                  <div className="frame-193">
                    <div className="frame-194">
                      <div className="div-6">
                        <div className="group-12">
                          <div className="overlap-group-25">
                            <img className="star-13" alt="Star" src="/img/star-1-5.png" />
                            <img className="star-14" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-198">
                          <div className="overlap-group-24">
                            <div className="text-wrapper-118">10</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="text-wrapper-119">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-119">16:00 AM</div>
                      </div>
                      <p className="text-wrapper-120">algun maquina que se apañe alguna gemita</p>
                    </div>
                  </div>
                  <div className="frame-193">
                    <div className="frame-194">
                      <div className="div-6">
                        <div className="group-12">
                          <div className="overlap-group-25">
                            <img className="star-13" alt="Star" src="/img/star-1-6.png" />
                            <img className="star-14" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-198">
                          <div className="overlap-23">
                            <div className="text-wrapper-121">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="text-wrapper-119">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-119">16:00 AM</div>
                      </div>
                      <p className="text-wrapper-120">anyone wanna join my 0.69 group battle for the jokes?</p>
                    </div>
                  </div>
                  <div className="frame-193">
                    <div className="frame-194">
                      <div className="div-6">
                        <div className="overlap-22">
                          <img className="star-11" alt="Star" src="/img/star-1-2x.png" />
                          <img className="star-12" alt="Star" src="/img/star-2.png" />
                          <div className="frame-195">
                            <div className="overlap-group-24">
                              <div className="text-wrapper-118">10</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="text-wrapper-119">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-119">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-120">admin</div>
                    </div>
                  </div>
                  <div className="frame-193">
                    <div className="frame-194">
                      <div className="div-6">
                        <div className="group-12">
                          <div className="overlap-group-25">
                            <img className="star-13" alt="Star" src="/img/star-1-1.png" />
                            <img className="star-14" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-198">
                          <div className="overlap-23">
                            <div className="text-wrapper-121">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="text-wrapper-119">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-119">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-122">CRAZY TERMINAL battle join</div>
                    </div>
                  </div>
                  <div className="frame-193">
                    <div className="frame-194">
                      <div className="div-6">
                        <div className="group-12">
                          <div className="overlap-group-25">
                            <img className="star-13" alt="Star" src="/img/star-1-2-2x.png" />
                            <img className="star-14" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-198">
                          <div className="overlap-24">
                            <div className="text-wrapper-123">1</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="text-wrapper-119">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-119">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-120">urob teraz 3way group</div>
                    </div>
                  </div>
                  <div className="frame-193">
                    <div className="frame-194">
                      <div className="div-6">
                        <div className="group-12">
                          <div className="overlap-group-25">
                            <img className="star-13" alt="Star" src="/img/star-1-3.png" />
                            <img className="star-14" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-198">
                          <div className="overlap-25">
                            <div className="text-wrapper-121">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="text-wrapper-119">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-119">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-120">someone join the jackpot</div>
                    </div>
                  </div>
                  <div className="frame-193">
                    <div className="frame-194">
                      <div className="div-6">
                        <div className="group-12">
                          <div className="overlap-group-25">
                            <img className="star-13" alt="Star" src="/img/star-1-4-2x.png" />
                            <img className="star-14" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-198">
                          <div className="overlap-24">
                            <div className="text-wrapper-123">1</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="text-wrapper-119">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-119">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-120">hola</div>
                    </div>
                  </div>
                  <div className="frame-193">
                    <div className="frame-194">
                      <div className="div-6">
                        <div className="group-12">
                          <div className="overlap-group-25">
                            <img className="star-13" alt="Star" src="/img/star-1-5.png" />
                            <img className="star-14" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-198">
                          <div className="overlap-group-24">
                            <div className="text-wrapper-118">10</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="text-wrapper-119">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-119">16:00 AM</div>
                      </div>
                      <p className="text-wrapper-120">algun maquina que se apañe alguna gemita</p>
                    </div>
                  </div>
                  <div className="frame-193">
                    <div className="frame-194">
                      <div className="div-6">
                        <div className="group-12">
                          <div className="overlap-group-25">
                            <img className="star-13" alt="Star" src="/img/star-1-6.png" />
                            <img className="star-14" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-198">
                          <div className="overlap-23">
                            <div className="text-wrapper-121">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="text-wrapper-119">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-119">16:00 AM</div>
                      </div>
                      <p className="text-wrapper-120">anyone wanna join my 0.69 group battle for the jokes?</p>
                    </div>
                  </div>
                </div>
                <div className="frame-199">
                  <div className="frame-200">
                    <div className="text-wrapper-124">Your message...</div>
                    <img className="smile-3" alt="Smile" src="/img/smile-7.png" />
                    <img className="frame-201" alt="Frame" src="/img/frame-37.png" />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {screenWidth < 900 && (
          <>
            <div className="overlap-26">
              <div className="rectangle-15" />
              <div className="frame-202">
                <div className="frame-203">
                  <div className="text-wrapper-125">Open for</div>
                  <div className="frame-154">
                    <img className="img-8" alt="Storage" src="/img/storage-5634434-1-268.png" />
                    <div className="text-wrapper-126">5.5</div>
                  </div>
                </div>
                <div className="frame-204">
                  <div className="navbar">
                    <div className="text-wrapper-127">X1</div>
                    <img className="vector-10" alt="Vector" src="/img/vector-3-2.png" />
                    <div className="text-wrapper-128">X2</div>
                    <img className="vector-10" alt="Vector" src="/img/vector-3-2.png" />
                    <div className="text-wrapper-128">X3</div>
                    <img className="vector-10" alt="Vector" src="/img/vector-3-2.png" />
                    <div className="text-wrapper-128">X4</div>
                  </div>
                  <div className="frame-205">
                    <div className="text-wrapper-129">Fast</div>
                    <img className="vuesax-bold-flash" alt="Vuesax bold flash" src="/img/vuesax-bold-flash-1.png" />
                  </div>
                </div>
              </div>
            </div>
            <div className="overlap-27">
              <div className="rectangle-16" />
              <div className="frame-206">
                <div className="frame-207">
                  <div className="text-wrapper-130">Case contents</div>
                  <p className="text-wrapper-131">All items that may fall out of your case</p>
                </div>
                <div className="frame-208">
                  <div className="frame-159">
                    <div className="frame-209">
                      <div className="overlap-group-26">
                        <div className="ellipse-43" />
                        <div className="frame-163">
                          <div className="text-wrapper-103">Clothing</div>
                          <div className="text-wrapper-132">Snowcamo Jacket</div>
                        </div>
                        <img className="image-11" alt="Image" src="/img/image-18.png" />
                      </div>
                    </div>
                    <div className="frame-210">
                      <div className="overlap-group-26">
                        <div className="ellipse-44" />
                        <img className="image-12" alt="Image" src="/img/image-19.png" />
                        <div className="frame-163">
                          <div className="text-wrapper-103">Weapon</div>
                          <div className="text-wrapper-132">Tempered AK47</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-159">
                    <div className="frame-211">
                      <div className="overlap-group-26">
                        <div className="ellipse-45" />
                        <img className="image-12" alt="Image" src="/img/image-4.png" />
                        <div className="frame-163">
                          <div className="text-wrapper-103">Misc</div>
                          <div className="text-wrapper-132">High Quality Bag</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-212">
                      <div className="overlap-group-26">
                        <div className="ellipse-46" />
                        <img className="image-11" alt="Image" src="/img/image-17.png" />
                        <div className="frame-161">
                          <div className="text-wrapper-103">Resource</div>
                          <div className="text-wrapper-133">Cloth</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-159">
                    <div className="frame-209">
                      <div className="overlap-group-26">
                        <div className="ellipse-43" />
                        <div className="frame-163">
                          <div className="text-wrapper-103">Clothing</div>
                          <div className="text-wrapper-132">Snowcamo Jacket</div>
                        </div>
                        <img className="image-11" alt="Image" src="/img/image-18.png" />
                      </div>
                    </div>
                    <div className="frame-210">
                      <div className="overlap-group-26">
                        <div className="ellipse-44" />
                        <img className="image-12" alt="Image" src="/img/image-19.png" />
                        <div className="frame-163">
                          <div className="text-wrapper-103">Weapon</div>
                          <div className="text-wrapper-132">Tempered AK47</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-159">
                    <div className="frame-211">
                      <div className="overlap-group-26">
                        <div className="ellipse-45" />
                        <img className="image-12" alt="Image" src="/img/image-4.png" />
                        <div className="frame-163">
                          <div className="text-wrapper-103">Misc</div>
                          <div className="text-wrapper-132">High Quality Bag</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-212">
                      <div className="overlap-group-26">
                        <div className="ellipse-46" />
                        <img className="image-11" alt="Image" src="/img/image-17.png" />
                        <div className="frame-161">
                          <div className="text-wrapper-103">Resource</div>
                          <div className="text-wrapper-133">Cloth</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-213">
                <div className="overlap-28">
                  <div className="line-wrapper">
                    <div className="line-9" />
                  </div>
                  <div className="frame-214">
                    <div className="frame-215">
                      <img className="img-8" alt="Home" src="/img/home-06-2.png" />
                      <div className="text-wrapper-134">Home</div>
                    </div>
                    <div className="frame-216">
                      <img className="img-8" alt="Gameboy" src="/img/gameboy-2.png" />
                      <div className="text-wrapper-135">Games</div>
                    </div>
                    <div className="frame-216">
                      <img className="img-8" alt="Chatting" src="/img/chatting-01.png" />
                      <div className="text-wrapper-135">Chat</div>
                    </div>
                    <div className="frame-216">
                      <div className="img-8">
                        <div className="frame-217">
                          <img className="rectangle-17" alt="Rectangle" src="/img/rectangle-1-1.png" />
                        </div>
                      </div>
                      <div className="text-wrapper-135">Settings</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
