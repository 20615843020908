import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import { VuesaxBoldWallet } from "../../components/VuesaxBoldWallet";
import { VuesaxLinearTick } from "../../components/VuesaxLinearTick";
import "./style.css";

export const UpgraderWaiting = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="upgrader-waiting">
      <div
        className="div-4"
        style={{
          height: screenWidth < 900 ? "1463px" : screenWidth >= 900 ? "1424px" : undefined,
          position: screenWidth >= 900 ? "relative" : undefined,
          width: screenWidth < 900 ? "375px" : screenWidth >= 900 ? "900px" : undefined,
        }}
      >
        <div
          className="overlap-116"
          style={{
            height: screenWidth >= 900 ? "1472px" : screenWidth < 900 ? "1969px" : undefined,
            left: screenWidth >= 900 ? "331px" : screenWidth < 900 ? "-22px" : undefined,
            position: screenWidth >= 900 ? "absolute" : screenWidth < 900 ? "relative" : undefined,
            top: screenWidth >= 900 ? "70px" : screenWidth < 900 ? "-217px" : undefined,
            width: screenWidth >= 900 ? "1589px" : screenWidth < 900 ? "418px" : undefined,
          }}
        >
          {screenWidth >= 900 && (
            <div className="side-bar-2">
              <img className="frame-47" alt="Frame" src="/img/frame-9-12.png" />
              <div className="frame-48">
                <div className="text-wrapper-33">18+</div>
              </div>
            </div>
          )}

          {screenWidth < 900 && (
            <>
              <div className="group-4">
                <div className="ellipse-wrapper">
                  <div className="ellipse-5" />
                </div>
              </div>
              <div className="rectangle-7" />
              <div className="rectangle-8" />
              <div className="frame-49">
                <div className="vuesax-linear-arrow-wrapper">
                  <img
                    className="vuesax-linear-arrow"
                    alt="Vuesax linear arrow"
                    src="/img/vuesax-linear-arrow-left.png"
                  />
                </div>
                <div className="text-wrapper-34">Upgrader</div>
                <div className="img-wrapper">
                  <img className="vuesax-linear-arrow-2" alt="Vuesax linear arrow" src="/img/chevron-down-1.png" />
                </div>
              </div>
            </>
          )}

          <div
            className="frame-50"
            style={{
              gap: screenWidth >= 900 ? "48px" : screenWidth < 900 ? "32px" : undefined,
              left: screenWidth >= 900 ? "21px" : screenWidth < 900 ? "43px" : undefined,
              top: screenWidth >= 900 ? "564px" : screenWidth < 900 ? "392px" : undefined,
              width: screenWidth >= 900 ? "1444px" : screenWidth < 900 ? "334px" : undefined,
            }}
          >
            <div
              className="frame-51"
              style={{
                alignItems: screenWidth < 900 ? "center" : screenWidth >= 900 ? "flex-start" : undefined,
                gap: screenWidth < 900 ? "28px" : screenWidth >= 900 ? "20px" : undefined,
              }}
            >
              <div
                className="group-5"
                style={{
                  alignItems: screenWidth >= 900 ? "center" : undefined,
                  display: screenWidth >= 900 ? "flex" : undefined,
                  flex: screenWidth >= 900 ? "0 0 auto" : undefined,
                  height: screenWidth < 900 ? "351px" : undefined,
                  justifyContent: screenWidth >= 900 ? "space-between" : undefined,
                  width: screenWidth < 900 ? "334px" : screenWidth >= 900 ? "1436px" : undefined,
                }}
              >
                {screenWidth < 900 && (
                  <div className="overlap-group-6">
                    <div className="ellipse-6" />
                    <img className="ellipse-7" alt="Ellipse" src="/img/ellipse-45-1.png" />
                    <img className="ellipse-8" alt="Ellipse" src="/img/ellipse-47-1.png" />
                    <img className="ellipse-9" alt="Ellipse" src="/img/ellipse-48-1.png" />
                    <div className="frame-52">
                      <div className="text-wrapper-35">Choose skin for upgrade</div>
                      <div className="frame-53">
                        <div className="text-wrapper-36">+</div>
                      </div>
                    </div>
                    <img className="polygon-3" alt="Polygon" src="/img/polygon-1-1.png" />
                  </div>
                )}

                {screenWidth >= 900 && (
                  <>
                    <div className="frame-54">
                      <div className="frame-55">
                        <img
                          className="vuesax-linear-search"
                          alt="Vuesax linear search"
                          src="/img/vuesax-linear-search-normal.png"
                        />
                        <div className="text-wrapper-37">Search</div>
                      </div>
                    </div>
                    <div className="frame-56">
                      <div className="frame-57">
                        <div className="text-wrapper-38">Prise descending</div>
                        <ChevronDownWrapper />
                      </div>
                      <div className="frame-58">
                        <div className="text-wrapper-37">0.00</div>
                        <div className="frame-59">
                          <div className="overlap-group-7">
                            <img className="vector-3" alt="Vector" src="/img/vector-145-1.png" />
                            <div className="ellipse-10" />
                            <div className="ellipse-11" />
                          </div>
                        </div>
                        <div className="text-wrapper-39">200.00</div>
                      </div>
                      <div className="frame-60">
                        <div className="frame-61">
                          <div className="text-wrapper-40">0-5</div>
                        </div>
                        <div className="frame-62">
                          <img className="storage-2" alt="Storage" src="/img/storage-5634434-1-271.png" />
                          <div className="text-wrapper-41">5-10</div>
                        </div>
                        <div className="frame-61">
                          <div className="text-wrapper-40">10-50</div>
                        </div>
                        <div className="frame-63">
                          <div className="text-wrapper-40">50-100</div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className="frame-64"
                style={{
                  alignItems: screenWidth < 900 ? "center" : screenWidth >= 900 ? "flex-start" : undefined,
                  gap: screenWidth < 900 ? "32px" : screenWidth >= 900 ? "8px" : undefined,
                  justifyContent: screenWidth < 900 ? "center" : undefined,
                }}
              >
                {screenWidth < 900 && (
                  <div className="frame-65">
                    <div className="frame-66">
                      <div className="frame-67">
                        <div className="text-wrapper-42">Bet amount</div>
                        <div className="frame-68">
                          <div className="frame-69">
                            <img className="img-5" alt="Storage" src="/img/storage-5634434-1-2.png" />
                            <div className="text-wrapper-43">500</div>
                          </div>
                          <div className="frame-70">
                            <div className="frame-71">
                              <p className="element">
                                <span className="text-wrapper-44">1</span>
                                <span className="text-wrapper-45">/</span>
                                <span className="text-wrapper-44">2</span>
                              </p>
                            </div>
                            <div className="frame-72">
                              <div className="text-wrapper-46">X2</div>
                            </div>
                            <div className="frame-71">
                              <div className="text-wrapper-47">Max</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-73">
                        <div className="overlap-group-8">
                          <img className="vector-4" alt="Vector" src="/img/vector-145-2.png" />
                          <img className="vector-5" alt="Vector" src="/img/vector-146-1.png" />
                          <div className="ellipse-12" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-67">
                      <div className="text-wrapper-42">Chance</div>
                      <div className="frame-68">
                        <div className="frame-69">
                          <div className="storage-3">
                            <div className="text-wrapper-48">X</div>
                          </div>
                          <div className="text-wrapper-49">5</div>
                        </div>
                        <div className="frame-74">
                          <div className="frame-75">
                            <div className="text-wrapper-50">X 1,5</div>
                          </div>
                          <div className="frame-75">
                            <div className="text-wrapper-47">X2</div>
                          </div>
                          <div className="frame-76">
                            <div className="text-wrapper-51">X3</div>
                          </div>
                          <div className="frame-75">
                            <div className="text-wrapper-47">X5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {screenWidth >= 900 && (
                  <>
                    <div className="frame-77">
                      <div className="frame-78">
                        <div className="overlap-group-9">
                          <div className="ellipse-13" />
                          <img className="image" alt="Image" src="/img/image-17.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Resource</div>
                              <div className="text-wrapper-53">Cloth</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">65</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-82">
                        <div className="overlap-group-9">
                          <div className="ellipse-14" />
                          <img className="image" alt="Image" src="/img/image-18.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-55">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">200</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-83">
                        <div className="overlap-group-9">
                          <div className="ellipse-15" />
                          <img className="image-2" alt="Image" src="/img/image-19.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Weapon</div>
                              <div className="text-wrapper-56">Tempered AK47</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">120</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-84">
                        <div className="overlap-8">
                          <div className="ellipse-16" />
                          <img className="image-2" alt="Image" src="/img/image-4.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-57">Misc</div>
                              <div className="text-wrapper-58">High Quality Bag</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">405</div>
                            </div>
                          </div>
                          <VuesaxLinearTick />
                        </div>
                      </div>
                      <div className="frame-78">
                        <div className="overlap-group-9">
                          <div className="ellipse-13" />
                          <img className="image" alt="Image" src="/img/image-17.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Resource</div>
                              <div className="text-wrapper-53">Cloth</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">65</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-82">
                        <div className="overlap-group-9">
                          <div className="ellipse-14" />
                          <img className="image" alt="Image" src="/img/image-18.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-55">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">200</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-83">
                        <div className="overlap-group-9">
                          <div className="ellipse-15" />
                          <img className="image-2" alt="Image" src="/img/image-19.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Weapon</div>
                              <div className="text-wrapper-56">Tempered AK47</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">120</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-85">
                        <div className="overlap-group-9">
                          <div className="ellipse-16" />
                          <img className="image-2" alt="Image" src="/img/image-4.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Misc</div>
                              <div className="text-wrapper-58">High Quality Bag</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">405</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-77">
                      <div className="frame-85">
                        <div className="overlap-group-9">
                          <div className="ellipse-16" />
                          <img className="image-2" alt="Image" src="/img/image-4.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Misc</div>
                              <div className="text-wrapper-58">High Quality Bag</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">405</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-78">
                        <div className="overlap-group-9">
                          <div className="ellipse-13" />
                          <img className="image" alt="Image" src="/img/image-17.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Resource</div>
                              <div className="text-wrapper-53">Cloth</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">65</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-82">
                        <div className="overlap-group-9">
                          <div className="ellipse-14" />
                          <img className="image" alt="Image" src="/img/image-18.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-55">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">200</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-83">
                        <div className="overlap-group-9">
                          <div className="ellipse-15" />
                          <img className="image-2" alt="Image" src="/img/image-19.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Weapon</div>
                              <div className="text-wrapper-56">Tempered AK47</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">120</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-78">
                        <div className="overlap-group-9">
                          <div className="ellipse-13" />
                          <img className="image" alt="Image" src="/img/image-17.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Resource</div>
                              <div className="text-wrapper-53">Cloth</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">65</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-82">
                        <div className="overlap-group-9">
                          <div className="ellipse-14" />
                          <img className="image" alt="Image" src="/img/image-18.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-55">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">200</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-83">
                        <div className="overlap-group-9">
                          <div className="ellipse-15" />
                          <img className="image-2" alt="Image" src="/img/image-19.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Weapon</div>
                              <div className="text-wrapper-56">Tempered AK47</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">120</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-85">
                        <div className="overlap-group-9">
                          <div className="ellipse-16" />
                          <img className="image-2" alt="Image" src="/img/image-4.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Misc</div>
                              <div className="text-wrapper-58">High Quality Bag</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">405</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-77">
                      <div className="frame-78">
                        <div className="overlap-group-9">
                          <div className="ellipse-13" />
                          <img className="image" alt="Image" src="/img/image-17.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Resource</div>
                              <div className="text-wrapper-53">Cloth</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">65</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-82">
                        <div className="overlap-group-9">
                          <div className="ellipse-14" />
                          <img className="image" alt="Image" src="/img/image-18.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-55">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">200</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-83">
                        <div className="overlap-group-9">
                          <div className="ellipse-15" />
                          <img className="image-2" alt="Image" src="/img/image-19.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Weapon</div>
                              <div className="text-wrapper-56">Tempered AK47</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">120</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-85">
                        <div className="overlap-group-9">
                          <div className="ellipse-16" />
                          <img className="image-2" alt="Image" src="/img/image-4.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Misc</div>
                              <div className="text-wrapper-58">High Quality Bag</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">405</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-78">
                        <div className="overlap-group-9">
                          <div className="ellipse-13" />
                          <img className="image" alt="Image" src="/img/image-17.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Resource</div>
                              <div className="text-wrapper-53">Cloth</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">65</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-82">
                        <div className="overlap-group-9">
                          <div className="ellipse-14" />
                          <img className="image" alt="Image" src="/img/image-18.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-55">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">200</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-83">
                        <div className="overlap-group-9">
                          <div className="ellipse-15" />
                          <img className="image-2" alt="Image" src="/img/image-19.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Weapon</div>
                              <div className="text-wrapper-56">Tempered AK47</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">120</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-85">
                        <div className="overlap-group-9">
                          <div className="ellipse-16" />
                          <img className="image-2" alt="Image" src="/img/image-4.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Misc</div>
                              <div className="text-wrapper-58">High Quality Bag</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">405</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-77">
                      <div className="frame-85">
                        <div className="overlap-group-9">
                          <div className="ellipse-16" />
                          <img className="image-2" alt="Image" src="/img/image-4.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Misc</div>
                              <div className="text-wrapper-58">High Quality Bag</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">405</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-78">
                        <div className="overlap-group-9">
                          <div className="ellipse-13" />
                          <img className="image" alt="Image" src="/img/image-17.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Resource</div>
                              <div className="text-wrapper-53">Cloth</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">65</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-82">
                        <div className="overlap-group-9">
                          <div className="ellipse-14" />
                          <img className="image" alt="Image" src="/img/image-18.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-55">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">200</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-83">
                        <div className="overlap-group-9">
                          <div className="ellipse-15" />
                          <img className="image-2" alt="Image" src="/img/image-19.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Weapon</div>
                              <div className="text-wrapper-56">Tempered AK47</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">120</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-78">
                        <div className="overlap-group-9">
                          <div className="ellipse-13" />
                          <img className="image" alt="Image" src="/img/image-17.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Resource</div>
                              <div className="text-wrapper-53">Cloth</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">65</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-82">
                        <div className="overlap-group-9">
                          <div className="ellipse-14" />
                          <img className="image" alt="Image" src="/img/image-18.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-55">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">200</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-83">
                        <div className="overlap-group-9">
                          <div className="ellipse-15" />
                          <img className="image-2" alt="Image" src="/img/image-19.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Weapon</div>
                              <div className="text-wrapper-56">Tempered AK47</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-272.png" />
                              <div className="text-wrapper-54">120</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-85">
                        <div className="overlap-group-9">
                          <div className="ellipse-16" />
                          <img className="image-2" alt="Image" src="/img/image-4.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Misc</div>
                              <div className="text-wrapper-58">High Quality Bag</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-4" alt="Storage" src="/img/storage-5634434-1-273.png" />
                              <div className="text-wrapper-54">405</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-77">
                      <div className="frame-78">
                        <div className="overlap-group-9">
                          <div className="ellipse-13" />
                          <img className="image" alt="Image" src="/img/image-17.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Resource</div>
                              <div className="text-wrapper-53">Cloth</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-5" alt="Storage" src="/img/chevron-down-1.png" />
                              <div className="text-wrapper-54">65</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-82">
                        <div className="overlap-group-9">
                          <div className="ellipse-14" />
                          <img className="image" alt="Image" src="/img/image-18.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-55">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-6" alt="Storage" src="/img/chevron-down-1.png" />
                              <div className="text-wrapper-54">200</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-83">
                        <div className="overlap-group-9">
                          <div className="ellipse-15" />
                          <img className="image-2" alt="Image" src="/img/image-19.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Weapon</div>
                              <div className="text-wrapper-56">Tempered AK47</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-7" alt="Storage" src="/img/chevron-down-1.png" />
                              <div className="text-wrapper-54">120</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-85">
                        <div className="overlap-group-9">
                          <div className="ellipse-16" />
                          <img className="image-2" alt="Image" src="/img/image-4.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Misc</div>
                              <div className="text-wrapper-58">High Quality Bag</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-8" alt="Storage" src="/img/chevron-down-1.png" />
                              <div className="text-wrapper-54">405</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-78">
                        <div className="overlap-group-9">
                          <div className="ellipse-13" />
                          <img className="image" alt="Image" src="/img/image-17.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Resource</div>
                              <div className="text-wrapper-53">Cloth</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-9" alt="Storage" src="/img/chevron-down-1.png" />
                              <div className="text-wrapper-54">65</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-82">
                        <div className="overlap-group-9">
                          <div className="ellipse-14" />
                          <img className="image" alt="Image" src="/img/image-18.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-55">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-10" alt="Storage" src="/img/chevron-down-1.png" />
                              <div className="text-wrapper-54">200</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-83">
                        <div className="overlap-group-9">
                          <div className="ellipse-15" />
                          <img className="image-2" alt="Image" src="/img/image-19.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Weapon</div>
                              <div className="text-wrapper-56">Tempered AK47</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-11" alt="Storage" src="/img/chevron-down-1.png" />
                              <div className="text-wrapper-54">120</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-85">
                        <div className="overlap-group-9">
                          <div className="ellipse-16" />
                          <img className="image-2" alt="Image" src="/img/image-4.png" />
                          <div className="frame-79">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Misc</div>
                              <div className="text-wrapper-58">High Quality Bag</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-12" alt="Storage" src="/img/chevron-down-1.png" />
                              <div className="text-wrapper-54">405</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {screenWidth < 900 && (
              <div className="frame-86">
                <div className="frame-87">
                  <div className="frame-88">
                    <div className="frame-89">
                      <div className="frame-90">
                        <img
                          className="vuesax-linear-search"
                          alt="Vuesax linear search"
                          src="/img/vuesax-linear-search-normal-1.png"
                        />
                        <div className="text-wrapper-59">Search</div>
                      </div>
                    </div>
                    <div className="frame-91">
                      <div className="text-wrapper-60">Prise descending</div>
                      <ChevronDownWrapper />
                    </div>
                  </div>
                  <div className="frame-92">
                    <div className="text-wrapper-59">0.00</div>
                    <div className="frame-59">
                      <div className="overlap-group-10">
                        <img className="vector-6" alt="Vector" src="/img/vector-145-3.png" />
                        <div className="ellipse-10" />
                        <div className="ellipse-17" />
                      </div>
                    </div>
                    <div className="text-wrapper-61">200.00</div>
                  </div>
                </div>
                <div className="frame-67">
                  <div className="frame-93">
                    <div className="frame-94">
                      <div className="frame-95">
                        <div className="overlap-group-11">
                          <div className="ellipse-18" />
                          <img className="image-3" alt="Image" src="/img/image-17.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Resource</div>
                              <div className="text-wrapper-62">Cloth</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="/img/storage-5634434-1-314.png" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-97">
                        <div className="overlap-group-11">
                          <div className="ellipse-19" />
                          <img className="image-3" alt="Image" src="/img/image-18.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-64">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="/img/storage-5634434-1-314.png" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-94">
                      <div className="frame-98">
                        <div className="overlap-group-12">
                          <div className="ellipse-20" />
                          <img className="image-4" alt="Image" src="/img/image-4.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-57">Misc</div>
                              <div className="text-wrapper-65">High Quality Bag</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="/img/storage-5634434-1-314.png" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                          <VuesaxLinearTick />
                        </div>
                      </div>
                      <div className="frame-99">
                        <div className="overlap-group-11">
                          <div className="ellipse-21" />
                          <img className="image-4" alt="Image" src="/img/image-19.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Weapon</div>
                              <div className="text-wrapper-66">Tempered AK47</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="/img/storage-5634434-1-314.png" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-93">
                    <div className="frame-94">
                      <div className="frame-95">
                        <div className="overlap-group-11">
                          <div className="ellipse-18" />
                          <img className="image-3" alt="Image" src="/img/image-17.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Resource</div>
                              <div className="text-wrapper-62">Cloth</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="/img/storage-5634434-1-314.png" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-97">
                        <div className="overlap-group-11">
                          <div className="ellipse-19" />
                          <img className="image-3" alt="Image" src="/img/image-18.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-64">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="/img/storage-5634434-1-314.png" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-94">
                      <div className="frame-97">
                        <div className="overlap-group-11">
                          <div className="ellipse-19" />
                          <img className="image-3" alt="Image" src="/img/image-18.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Clothing</div>
                              <div className="text-wrapper-64">Snowcamo Jacket</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="/img/storage-5634434-1-314.png" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-99">
                        <div className="overlap-group-11">
                          <div className="ellipse-21" />
                          <img className="image-4" alt="Image" src="/img/image-19.png" />
                          <div className="frame-96">
                            <div className="frame-80">
                              <div className="text-wrapper-52">Weapon</div>
                              <div className="text-wrapper-66">Tempered AK47</div>
                            </div>
                            <div className="frame-81">
                              <img className="storage-13" alt="Storage" src="/img/storage-5634434-1-314.png" />
                              <div className="text-wrapper-63">24.5</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-94">
                    <div className="frame-100">
                      <div className="overlap-group-13">
                        <div className="ellipse-22" />
                        <img className="image-5" alt="Image" src="/img/image-3.png" />
                        <div className="frame-101">
                          <div className="frame-80">
                            <div className="text-wrapper-52">MAG-7</div>
                            <div className="text-wrapper-62">B139 Spectrum</div>
                          </div>
                          <div className="frame-81">
                            <img className="storage-14" alt="Storage" src="/img/chevron-down-1.png" />
                            <div className="text-wrapper-63">24.5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-102">
                      <div className="overlap-group-13">
                        <div className="ellipse-23" />
                        <img className="image-5" alt="Image" src="/img/image-3-1.png" />
                        <div className="frame-101">
                          <div className="frame-80">
                            <div className="text-wrapper-52">MAG-7</div>
                            <div className="text-wrapper-62">B139 Spectrum</div>
                          </div>
                          <div className="frame-81">
                            <img className="storage-15" alt="Storage" src="/img/chevron-down-1.png" />
                            <div className="text-wrapper-63">24.5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-94">
                    <div className="frame-102">
                      <div className="overlap-group-14">
                        <div className="ellipse-24" />
                        <img className="image-5" alt="Image" src="/img/image-3-1.png" />
                        <div className="frame-103">
                          <div className="frame-80">
                            <div className="text-wrapper-52">MAG-7</div>
                            <div className="text-wrapper-62">B139 Spectrum</div>
                          </div>
                          <div className="frame-81">
                            <img className="storage-16" alt="Storage" src="/img/chevron-down-1.png" />
                            <div className="text-wrapper-63">24.5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-100">
                      <div className="overlap-group-13">
                        <div className="ellipse-22" />
                        <img className="image-5" alt="Image" src="/img/image-3.png" />
                        <div className="frame-101">
                          <div className="frame-80">
                            <div className="text-wrapper-52">MAG-7</div>
                            <div className="text-wrapper-62">B139 Spectrum</div>
                          </div>
                          <div className="frame-81">
                            <img className="storage-17" alt="Storage" src="/img/chevron-down-1.png" />
                            <div className="text-wrapper-63">24.5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="rectangle-9"
            style={{
              height: screenWidth >= 900 ? "241px" : screenWidth < 900 ? "167px" : undefined,
              left: screenWidth >= 900 ? "0" : screenWidth < 900 ? "22px" : undefined,
              top: screenWidth >= 900 ? "1113px" : screenWidth < 900 ? "1513px" : undefined,
              width: screenWidth >= 900 ? "1506px" : screenWidth < 900 ? "373px" : undefined,
            }}
          />
          {screenWidth < 900 && (
            <>
              <div className="frame-104">
                <div className="overlap-9">
                  <div className="element-indicator">
                    <div className="line-3" />
                  </div>
                  <div className="frame-105">
                    <div className="frame-106">
                      <img className="img-5" alt="Home" src="/img/home-06-2.png" />
                      <div className="text-wrapper-67">Home</div>
                    </div>
                    <div className="frame-107">
                      <img className="img-5" alt="Gameboy" src="/img/gameboy-2.png" />
                      <div className="text-wrapper-68">Games</div>
                    </div>
                    <div className="frame-107">
                      <img className="img-5" alt="Chatting" src="/img/chatting-01.png" />
                      <div className="text-wrapper-68">Chat</div>
                    </div>
                    <div className="frame-107">
                      <div className="img-5">
                        <div className="frame-108">
                          <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-1-1.png" />
                        </div>
                      </div>
                      <div className="text-wrapper-68">Settings</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-109">
                <div className="element-system-bar">
                  <div className="group-6">
                    <div className="battery">
                      <div className="capacity-wrapper">
                        <div className="capacity" />
                      </div>
                      <img className="cap" alt="Cap" src="/img/cap-1.png" />
                    </div>
                    <img className="wifi" alt="Wifi" src="/img/wifi-1.png" />
                    <img
                      className="cellular-connection"
                      alt="Cellular connection"
                      src="/img/cellular-connection-3.png"
                    />
                  </div>
                  <div className="starus">
                    <p className="time">
                      <span className="text-wrapper-69">9:4</span>
                      <span className="text-wrapper-70">1</span>
                    </p>
                  </div>
                  <div className="floating-island" />
                </div>
                <div className="frame-110">
                  <img className="logo-2" alt="Logo" src="/img/logo-38.png" />
                  <div className="frame-55">
                    <div className="frame-81">
                      <img className="img-5" alt="Storage" src="/img/storage-5634434-1-2.png" />
                      <div className="text-wrapper-61">789</div>
                    </div>
                    <div className="frame-69">
                      <div className="frame-111">
                        <div className="frame-112">
                          <div className="text-wrapper-71">Deposit</div>
                        </div>
                      </div>
                      <div className="vuesax-bold-wallet-2-wrapper">
                        <VuesaxBoldWallet
                          className="vuesax-bold-wallet-instance"
                          vuesaxBoldWallet="/img/vuesax-bold-wallet-2-18.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {screenWidth >= 900 && (
          <>
            <div className="overlap-10">
              <img className="vector-7" alt="Vector" src="/img/vector-79.png" />
              <div className="ellipse-25" />
              <div className="text-wrapper-72">Upgrader</div>
              <div className="frame-113">
                <div className="frame-114">
                  <div className="text-wrapper-73">Choose sum to start</div>
                  <div className="frame-115">
                    <div className="frame-66">
                      <div className="frame-66">
                        <div className="text-wrapper-42">Bet amount</div>
                        <div className="frame-68">
                          <div className="frame-90">
                            <img className="img-6" alt="Storage" src="/img/storage-5634434-1-270.png" />
                            <div className="text-wrapper-39">500</div>
                          </div>
                          <div className="frame-70">
                            <div className="frame-71">
                              <p className="element">
                                <span className="text-wrapper-44">1</span>
                                <span className="text-wrapper-45">/</span>
                                <span className="text-wrapper-44">2</span>
                              </p>
                            </div>
                            <div className="frame-72">
                              <div className="text-wrapper-74">X2</div>
                            </div>
                            <div className="frame-71">
                              <div className="text-wrapper-75">Max</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-73">
                        <div className="overlap-group-15">
                          <img className="vector-8" alt="Vector" src="/img/vector-145.png" />
                          <img className="vector-5" alt="Vector" src="/img/vector-146.png" />
                          <div className="ellipse-12" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-66">
                      <div className="text-wrapper-42">Chance</div>
                      <div className="frame-68">
                        <div className="frame-90">
                          <div className="img-6">
                            <div className="text-wrapper-76">X</div>
                          </div>
                          <div className="text-wrapper-37">5</div>
                        </div>
                        <div className="frame-74">
                          <div className="frame-75">
                            <p className="x-3">
                              <span className="text-wrapper-77">X</span>
                              <span className="text-wrapper-78">&nbsp;</span>
                              <span className="text-wrapper-77">1,5</span>
                            </p>
                          </div>
                          <div className="frame-75">
                            <div className="text-wrapper-75">X2</div>
                          </div>
                          <div className="frame-76">
                            <div className="text-wrapper-79">X3</div>
                          </div>
                          <div className="frame-75">
                            <div className="text-wrapper-75">X5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-7">
                  <div className="overlap-11">
                    <div className="ellipse-26" />
                    <img className="ellipse-27" alt="Ellipse" src="/img/ellipse-45.png" />
                    <img className="ellipse-28" alt="Ellipse" src="/img/ellipse-47.png" />
                    <img className="ellipse-29" alt="Ellipse" src="/img/ellipse-48.png" />
                    <div className="frame-116">
                      <div className="frame-67">
                        <div className="text-wrapper-80">75%</div>
                        <div className="text-wrapper-81">Your chance to upgrade</div>
                      </div>
                      <div className="frame-117">
                        <div className="text-wrapper-82">Upgrade</div>
                      </div>
                    </div>
                    <img className="polygon-4" alt="Polygon" src="/img/polygon-1.png" />
                  </div>
                </div>
                <div className="frame-118">
                  <div className="frame-119">
                    <div className="text-wrapper-83">Skin for upgrade</div>
                    <div className="text-wrapper-84">Choose skin for upgrade</div>
                  </div>
                  <div className="frame-120">
                    <div className="overlap-group-16">
                      <div className="ellipse-30" />
                      <div className="frame-121">
                        <div className="text-wrapper-36">+</div>
                      </div>
                    </div>
                    <VuesaxLinearTick />
                  </div>
                </div>
              </div>
              <header className="header-2">
                <div className="overlap-12">
                  <div className="ellipse-31" />
                  <img className="line-4" alt="Line" src="/img/line-1.png" />
                </div>
                <div className="frame-122">
                  <div className="row-2">
                    <img className="img-6" alt="Home" src="/img/home-06.png" />
                    <div className="text-wrapper-38">Home</div>
                  </div>
                </div>
                <div className="frame-123">
                  <div className="frame-124">
                    <div className="frame-81">
                      <img className="img-6" alt="Storage" src="/img/storage-5634434-1.png" />
                      <div className="text-wrapper-39">789</div>
                    </div>
                    <div className="frame-81">
                      <div className="frame-125">
                        <div className="text-wrapper-41">Deposit</div>
                      </div>
                      <div className="vuesax-bold-wallet-instance-wrapper">
                        <VuesaxBoldWallet
                          className="vuesax-bold-wallet-2-instance"
                          vuesaxBoldWallet="/img/vuesax-bold-wallet-2.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="frame-90">
                    <div className="frame-126">
                      <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-1.png" />
                      <div className="frame-127">
                        <div className="text-wrapper-85">John Watson</div>
                        <div className="group-8">
                          <div className="rectangle-12" />
                        </div>
                      </div>
                      <ChevronDownWrapper />
                    </div>
                  </div>
                </div>
                <img className="line-5" alt="Line" src="/img/line-2.png" />
                <div className="frame-128">
                  <img className="img-6" alt="Gameboy" src="/img/gameboy.png" />
                  <div className="text-wrapper-86">Games</div>
                  <ChevronDownWrapper />
                </div>
                <img className="logo-3" alt="Logo" src="/img/logo-35.png" />
              </header>
            </div>
            <div className="chat-2">
              <div className="chat-header-2">
                <div className="overlap-group-17">
                  <div className="ellipse-32" />
                  <div className="frame-129">
                    <img className="frame-130" alt="Frame" src="/img/frame-1-7.png" />
                    <div className="text-wrapper-85">Chat</div>
                  </div>
                </div>
                <div className="frame-131">
                  <div className="frame-81">
                    <div className="ellipse-33" />
                    <div className="text-wrapper-86">456</div>
                  </div>
                  <div className="frame-132">
                    <Gb className="instance-node" />
                    <div className="text-wrapper-86">ENG</div>
                    <ChevronDownWrapper />
                  </div>
                </div>
              </div>
              <div className="overlap-13">
                <div className="frame-133">
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="overlap-14">
                          <img className="star-7" alt="Star" src="/img/star-1-2x.png" />
                          <img className="star-8" alt="Star" src="/img/star-2.png" />
                          <div className="frame-136">
                            <div className="overlap-group-18">
                              <div className="text-wrapper-87">10</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-89">admin</div>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="group-9">
                          <div className="overlap-group-19">
                            <img className="star-9" alt="Star" src="/img/star-1-1.png" />
                            <img className="star-10" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-139">
                          <div className="overlap-15">
                            <div className="text-wrapper-90">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-91">CRAZY TERMINAL battle join</div>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="group-9">
                          <div className="overlap-group-19">
                            <img className="star-9" alt="Star" src="/img/star-1-2-2x.png" />
                            <img className="star-10" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-139">
                          <div className="overlap-16">
                            <div className="text-wrapper-92">1</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-89">urob teraz 3way group</div>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="group-9">
                          <div className="overlap-group-19">
                            <img className="star-9" alt="Star" src="/img/star-1-3.png" />
                            <img className="star-10" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-139">
                          <div className="overlap-17">
                            <div className="text-wrapper-90">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-89">someone join the jackpot</div>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="group-9">
                          <div className="overlap-group-19">
                            <img className="star-9" alt="Star" src="/img/star-1-4-2x.png" />
                            <img className="star-10" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-139">
                          <div className="overlap-16">
                            <div className="text-wrapper-92">1</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-89">hola</div>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="group-9">
                          <div className="overlap-group-19">
                            <img className="star-9" alt="Star" src="/img/star-1-5.png" />
                            <img className="star-10" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-139">
                          <div className="overlap-group-18">
                            <div className="text-wrapper-87">10</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <p className="text-wrapper-89">algun maquina que se apañe alguna gemita</p>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="group-9">
                          <div className="overlap-group-19">
                            <img className="star-9" alt="Star" src="/img/star-1-6.png" />
                            <img className="star-10" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-139">
                          <div className="overlap-15">
                            <div className="text-wrapper-90">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <p className="text-wrapper-89">anyone wanna join my 0.69 group battle for the jokes?</p>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="overlap-14">
                          <img className="star-7" alt="Star" src="/img/star-1-2x.png" />
                          <img className="star-8" alt="Star" src="/img/star-2.png" />
                          <div className="frame-136">
                            <div className="overlap-group-18">
                              <div className="text-wrapper-87">10</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-89">admin</div>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="group-9">
                          <div className="overlap-group-19">
                            <img className="star-9" alt="Star" src="/img/star-1-1.png" />
                            <img className="star-10" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-139">
                          <div className="overlap-15">
                            <div className="text-wrapper-90">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-91">CRAZY TERMINAL battle join</div>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="group-9">
                          <div className="overlap-group-19">
                            <img className="star-9" alt="Star" src="/img/star-1-2-2x.png" />
                            <img className="star-10" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-139">
                          <div className="overlap-16">
                            <div className="text-wrapper-92">1</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-89">urob teraz 3way group</div>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="group-9">
                          <div className="overlap-group-19">
                            <img className="star-9" alt="Star" src="/img/star-1-3.png" />
                            <img className="star-10" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-139">
                          <div className="overlap-17">
                            <div className="text-wrapper-90">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-89">someone join the jackpot</div>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="group-9">
                          <div className="overlap-group-19">
                            <img className="star-9" alt="Star" src="/img/star-1-4-2x.png" />
                            <img className="star-10" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-139">
                          <div className="overlap-16">
                            <div className="text-wrapper-92">1</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-89">hola</div>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="group-9">
                          <div className="overlap-group-19">
                            <img className="star-9" alt="Star" src="/img/star-1-5.png" />
                            <img className="star-10" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-139">
                          <div className="overlap-group-18">
                            <div className="text-wrapper-87">10</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <p className="text-wrapper-89">algun maquina que se apañe alguna gemita</p>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="group-9">
                          <div className="overlap-group-19">
                            <img className="star-9" alt="Star" src="/img/star-1-6.png" />
                            <img className="star-10" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-139">
                          <div className="overlap-15">
                            <div className="text-wrapper-90">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <p className="text-wrapper-89">anyone wanna join my 0.69 group battle for the jokes?</p>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="group-9">
                          <div className="overlap-group-19">
                            <img className="star-9" alt="Star" src="/img/star-1-4-2x.png" />
                            <img className="star-10" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-139">
                          <div className="overlap-16">
                            <div className="text-wrapper-92">1</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <div className="text-wrapper-89">hola</div>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="group-9">
                          <div className="overlap-group-19">
                            <img className="star-9" alt="Star" src="/img/star-1-5.png" />
                            <img className="star-10" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-139">
                          <div className="overlap-group-18">
                            <div className="text-wrapper-87">10</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <p className="text-wrapper-89">algun maquina que se apañe alguna gemita</p>
                    </div>
                  </div>
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="div-5">
                        <div className="group-9">
                          <div className="overlap-group-19">
                            <img className="star-9" alt="Star" src="/img/star-1-6.png" />
                            <img className="star-10" alt="Star" src="/img/star-2.png" />
                          </div>
                        </div>
                        <div className="frame-139">
                          <div className="overlap-15">
                            <div className="text-wrapper-90">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-137">
                      <div className="frame-138">
                        <div className="text-wrapper-88">ツ HXNRY 1X ツ</div>
                        <div className="text-wrapper-88">16:00 AM</div>
                      </div>
                      <p className="text-wrapper-89">anyone wanna join my 0.69 group battle for the jokes?</p>
                    </div>
                  </div>
                </div>
                <div className="frame-140">
                  <div className="frame-141">
                    <div className="text-wrapper-93">Your message...</div>
                    <img className="smile-2" alt="Smile" src="/img/smile-7.png" />
                    <img className="frame-142" alt="Frame" src="/img/frame-37.png" />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
