/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const VuesaxBoldWallet = ({ className, vuesaxBoldWallet = "/img/vuesax-bold-wallet-2.png" }) => {
  return <img className={`vuesax-bold-wallet ${className}`} alt="Vuesax bold wallet" src={vuesaxBoldWallet} />;
};

VuesaxBoldWallet.propTypes = {
  vuesaxBoldWallet: PropTypes.string,
};
