import React from "react";
import { ChevronDownWrapper } from "../../components/ChevronDownWrapper";
import { Gb } from "../../components/Gb";
import { VuesaxBoldWallet } from "../../components/VuesaxBoldWallet";
import { VuesaxLinearTick } from "../../components/VuesaxLinearTick";
import "./style.css";

export const RouletteInGame = () => {
  return (
    <div className="roulette-in-game">
      <div className="div">
        <div className="overlap">
          <div className="side-bar">
            <img className="frame" alt="Frame" src="/img/frame-9-12.png" />
            <div className="div-wrapper">
              <div className="text-wrapper">18+</div>
            </div>
          </div>
          <img className="vector" alt="Vector" src="/img/vector-79.png" />
          <div className="ellipse" />
          <div className="text-wrapper-2">Roulette</div>
          <div className="text-wrapper-3">In game</div>
          <div className="rectangle" />
          <div className="group">
            <div className="overlap-group-wrapper">
              <div className="overlap-group">
                <img className="img" alt="Frame" src="/img/frame-427319415.png" />
                <div className="group-2">
                  <img className="polygon" alt="Polygon" src="/img/polygon-1-2.png" />
                  <img className="polygon-2" alt="Polygon" src="/img/polygon-2.png" />
                </div>
                <div className="rectangle-2" />
                <div className="rectangle-3" />
              </div>
            </div>
            <div className="frame-2">
              <div className="frame-3">
                <div className="text-wrapper-4">Recent Rolls</div>
                <img className="frame-4" alt="Frame" src="/img/frame-427319418.png" />
                <div className="rectangle-4" />
              </div>
              <div className="frame-5">
                <img className="img-2" alt="Storage" src="/img/storage-5634434-1-270.png" />
                <div className="text-wrapper-5">789</div>
              </div>
              <div className="frame-6">
                <div className="text-wrapper-6">Last 100 rolls</div>
                <div className="frame-7">
                  <div className="frame-8">
                    <img
                      className="img-3"
                      alt="Camping tent outdoor"
                      src="/img/camping-tent-outdoor-recreation-camping-tent-teepee-tipi-travel.png"
                    />
                    <div className="text-wrapper-7">45</div>
                  </div>
                  <div className="frame-9">
                    <img className="img-3" alt="Iconex filled fire" src="/img/iconex-filled-fire.png" />
                    <div className="text-wrapper-8">35</div>
                  </div>
                  <div className="frame-10">
                    <img className="img-3" alt="Vuesax bold tree" src="/img/vuesax-bold-tree.png" />
                    <div className="text-wrapper-9">10</div>
                  </div>
                  <div className="frame-11">
                    <img className="img-3" alt="Iconex filled fire" src="/img/iconex-filled-fire-2.png" />
                    <div className="text-wrapper-10">5</div>
                  </div>
                </div>
              </div>
            </div>
            <img className="vector-2" alt="Vector" src="/img/vector-151.png" />
          </div>
          <div className="frame-12">
            <div className="frame-wrapper">
              <div className="frame-13">
                <img className="img-4" alt="Iconex filled fire" src="/img/iconex-filled-fire-1.png" />
                <p className="x">
                  <span className="span">X</span>
                  <span className="text-wrapper-11">&nbsp;</span>
                  <span className="text-wrapper-12">2</span>
                </p>
              </div>
            </div>
            <div className="frame-14">
              <div className="frame-15">
                <div className="frame-7">
                  <img
                    className="img-4"
                    alt="Camping tent outdoor"
                    src="/img/camping-tent-outdoor-recreation-camping-tent-teepee-tipi-travel-1.png"
                  />
                  <p className="p">
                    <span className="span">X</span>
                    <span className="text-wrapper-11">&nbsp;</span>
                    <span className="text-wrapper-12">2</span>
                  </p>
                </div>
                <VuesaxLinearTick />
              </div>
            </div>
            <div className="frame-16">
              <div className="frame-13">
                <img className="img-4" alt="Vuesax bold tree" src="/img/vuesax-bold-tree-1.png" />
                <p className="x-2">
                  <span className="span">X</span>
                  <span className="text-wrapper-11">&nbsp;</span>
                  <span className="text-wrapper-12">7</span>
                </p>
              </div>
            </div>
            <div className="frame-17">
              <div className="frame-13">
                <img className="img-4" alt="Iconex filled fire" src="/img/iconex-filled-fire-3.png" />
                <div className="text-wrapper-13">X 14</div>
              </div>
            </div>
          </div>
          <div className="frame-18">
            <div className="frame-19">
              <p className="div-2">
                <span className="text-wrapper-14">Fire </span>
                <span className="text-wrapper-15">Orange</span>
              </p>
              <div className="frame-20">
                <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
                <div className="text-wrapper-16">5.689</div>
              </div>
            </div>
            <div className="frame-21">
              <div className="frame-7">
                <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-1.png" />
                <div className="frame-22">
                  <div className="text-wrapper-17">John Watson</div>
                </div>
              </div>
              <div className="frame-23">
                <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
                <div className="text-wrapper-16">789</div>
              </div>
            </div>
            <div className="frame-21">
              <div className="frame-7">
                <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-1.png" />
                <div className="frame-22">
                  <div className="text-wrapper-17">John Watson</div>
                </div>
              </div>
              <div className="frame-23">
                <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
                <div className="text-wrapper-16">789</div>
              </div>
            </div>
            <div className="frame-21">
              <div className="frame-7">
                <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-1.png" />
                <div className="frame-22">
                  <div className="text-wrapper-17">John Watson</div>
                </div>
              </div>
              <div className="frame-23">
                <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
                <div className="text-wrapper-16">789</div>
              </div>
            </div>
            <div className="frame-21">
              <div className="frame-7">
                <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-1.png" />
                <div className="frame-22">
                  <div className="text-wrapper-17">John Watson</div>
                </div>
              </div>
              <div className="frame-23">
                <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
                <div className="text-wrapper-16">789</div>
              </div>
            </div>
            <div className="frame-21">
              <div className="frame-7">
                <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-1.png" />
                <div className="frame-22">
                  <div className="text-wrapper-17">John Watson</div>
                </div>
              </div>
              <div className="frame-23">
                <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
                <div className="text-wrapper-16">789</div>
              </div>
            </div>
          </div>
          <div className="frame-24">
            <div className="frame-19">
              <p className="div-2">
                <span className="text-wrapper-14">Tent </span>
                <span className="text-wrapper-18">Grey</span>
              </p>
              <div className="frame-20">
                <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
                <div className="text-wrapper-16">5.689</div>
              </div>
            </div>
            <div className="frame-21">
              <div className="frame-7">
                <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-1.png" />
                <div className="frame-25">
                  <div className="frame-26">
                    <div className="text-wrapper-17">John Watson</div>
                    <div className="text-wrapper-19">You</div>
                  </div>
                </div>
              </div>
              <div className="frame-27">
                <img className="storage" alt="Storage" src="/img/storage-5634434-1-335.png" />
                <div className="text-wrapper-16">789</div>
              </div>
            </div>
            <div className="frame-21">
              <div className="frame-7">
                <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-1.png" />
                <div className="frame-22">
                  <div className="text-wrapper-17">John Watson</div>
                </div>
              </div>
              <div className="frame-23">
                <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
                <div className="text-wrapper-16">789</div>
              </div>
            </div>
            <div className="frame-21">
              <div className="frame-7">
                <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-1.png" />
                <div className="frame-22">
                  <div className="text-wrapper-17">John Watson</div>
                </div>
              </div>
              <div className="frame-23">
                <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
                <div className="text-wrapper-16">789</div>
              </div>
            </div>
            <div className="frame-21">
              <div className="frame-7">
                <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-1.png" />
                <div className="frame-22">
                  <div className="text-wrapper-17">John Watson</div>
                </div>
              </div>
              <div className="frame-23">
                <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
                <div className="text-wrapper-16">789</div>
              </div>
            </div>
          </div>
          <header className="header">
            <div className="overlap-2">
              <div className="ellipse-2" />
              <img className="line" alt="Line" src="/img/line-1.png" />
            </div>
            <div className="row-wrapper">
              <div className="row">
                <img className="img-2" alt="Home" src="/img/home-06.png" />
                <div className="text-wrapper-20">Home</div>
              </div>
            </div>
            <div className="frame-28">
              <div className="frame-29">
                <div className="frame-30">
                  <img className="img-2" alt="Storage" src="/img/storage-5634434-1.png" />
                  <div className="text-wrapper-21">789</div>
                </div>
                <div className="frame-30">
                  <div className="frame-31">
                    <div className="text-wrapper-22">Deposit</div>
                  </div>
                  <div className="vuesax-bold-wallet-wrapper">
                    <VuesaxBoldWallet className="vuesax-bold-wallet-2" />
                  </div>
                </div>
              </div>
              <div className="frame-7">
                <div className="frame-32">
                  <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-1.png" />
                  <div className="frame-22">
                    <div className="text-wrapper-17">John Watson</div>
                    <div className="rectangle-wrapper">
                      <div className="rectangle-6" />
                    </div>
                  </div>
                  <ChevronDownWrapper />
                </div>
              </div>
            </div>
            <img className="line-2" alt="Line" src="/img/line-2.png" />
            <div className="frame-33">
              <img className="img-2" alt="Gameboy" src="/img/gameboy.png" />
              <div className="text-wrapper-23">Games</div>
              <ChevronDownWrapper />
            </div>
            <img className="logo" alt="Logo" src="/img/logo-41.png" />
          </header>
        </div>
        <div className="frame-34">
          <div className="frame-19">
            <p className="div-2">
              <span className="text-wrapper-14">Tree </span>
              <span className="text-wrapper-24">Green</span>
            </p>
            <div className="frame-20">
              <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
              <div className="text-wrapper-16">5.689</div>
            </div>
          </div>
          <div className="frame-21">
            <div className="frame-7">
              <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-1.png" />
              <div className="frame-22">
                <div className="text-wrapper-17">John Watson</div>
              </div>
            </div>
            <div className="frame-23">
              <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
              <div className="text-wrapper-16">789</div>
            </div>
          </div>
          <div className="frame-21">
            <div className="frame-7">
              <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-1.png" />
              <div className="frame-22">
                <div className="text-wrapper-17">John Watson</div>
              </div>
            </div>
            <div className="frame-23">
              <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
              <div className="text-wrapper-16">789</div>
            </div>
          </div>
        </div>
        <div className="frame-35">
          <div className="frame-19">
            <p className="div-2">
              <span className="text-wrapper-14">Volcano </span>
              <span className="text-wrapper-25">Red</span>
            </p>
            <div className="frame-20">
              <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
              <div className="text-wrapper-16">5.689</div>
            </div>
          </div>
          <div className="frame-21">
            <div className="frame-7">
              <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-1.png" />
              <div className="frame-22">
                <div className="text-wrapper-17">John Watson</div>
              </div>
            </div>
            <div className="frame-23">
              <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
              <div className="text-wrapper-16">789</div>
            </div>
          </div>
          <div className="frame-21">
            <div className="frame-7">
              <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-1.png" />
              <div className="frame-22">
                <div className="text-wrapper-17">John Watson</div>
              </div>
            </div>
            <div className="frame-23">
              <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
              <div className="text-wrapper-16">789</div>
            </div>
          </div>
          <div className="frame-21">
            <div className="frame-7">
              <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-1.png" />
              <div className="frame-22">
                <div className="text-wrapper-17">John Watson</div>
              </div>
            </div>
            <div className="frame-23">
              <img className="storage" alt="Storage" src="/img/storage-5634434-1-327.png" />
              <div className="text-wrapper-16">789</div>
            </div>
          </div>
        </div>
        <div className="chat">
          <div className="chat-header">
            <div className="overlap-group-2">
              <div className="ellipse-3" />
              <div className="frame-36">
                <img className="frame-4" alt="Frame" src="/img/frame-1-13.png" />
                <div className="text-wrapper-17">Chat</div>
              </div>
            </div>
            <div className="frame-37">
              <div className="frame-30">
                <div className="ellipse-4" />
                <div className="text-wrapper-23">456</div>
              </div>
              <div className="frame-38">
                <Gb className="gb-instance" />
                <div className="text-wrapper-23">ENG</div>
                <ChevronDownWrapper />
              </div>
            </div>
          </div>
          <div className="overlap-3">
            <div className="frame-39">
              <div className="frame-40">
                <div className="frame-41">
                  <div className="div-3">
                    <div className="overlap-4">
                      <img className="star" alt="Star" src="/img/star-1-2x.png" />
                      <img className="star-2" alt="Star" src="/img/star-2.png" />
                      <div className="frame-42">
                        <div className="overlap-group-3">
                          <div className="text-wrapper-26">10</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-43">
                  <div className="frame-15">
                    <div className="text-wrapper-27">ツ HXNRY 1X ツ</div>
                    <div className="text-wrapper-27">16:00 AM</div>
                  </div>
                  <div className="text-wrapper-28">admin</div>
                </div>
              </div>
              <div className="frame-40">
                <div className="frame-41">
                  <div className="div-3">
                    <div className="group-3">
                      <div className="overlap-group-4">
                        <img className="star-3" alt="Star" src="/img/star-1-1.png" />
                        <img className="star-4" alt="Star" src="/img/star-2.png" />
                      </div>
                    </div>
                    <div className="overlap-wrapper">
                      <div className="overlap-5">
                        <div className="text-wrapper-29">5</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-43">
                  <div className="frame-15">
                    <div className="text-wrapper-27">ツ HXNRY 1X ツ</div>
                    <div className="text-wrapper-27">16:00 AM</div>
                  </div>
                  <div className="text-wrapper-30">CRAZY TERMINAL battle join</div>
                </div>
              </div>
              <div className="frame-40">
                <div className="frame-41">
                  <div className="div-3">
                    <div className="group-3">
                      <div className="overlap-group-4">
                        <img className="star-3" alt="Star" src="/img/star-1-2-2x.png" />
                        <img className="star-4" alt="Star" src="/img/star-2.png" />
                      </div>
                    </div>
                    <div className="overlap-wrapper">
                      <div className="overlap-6">
                        <div className="text-wrapper-31">1</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-43">
                  <div className="frame-15">
                    <div className="text-wrapper-27">ツ HXNRY 1X ツ</div>
                    <div className="text-wrapper-27">16:00 AM</div>
                  </div>
                  <div className="text-wrapper-28">urob teraz 3way group</div>
                </div>
              </div>
              <div className="frame-40">
                <div className="frame-41">
                  <div className="div-3">
                    <div className="group-3">
                      <div className="overlap-group-4">
                        <img className="star-3" alt="Star" src="/img/star-1-3.png" />
                        <img className="star-4" alt="Star" src="/img/star-2.png" />
                      </div>
                    </div>
                    <div className="overlap-wrapper">
                      <div className="overlap-7">
                        <div className="text-wrapper-29">5</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-43">
                  <div className="frame-15">
                    <div className="text-wrapper-27">ツ HXNRY 1X ツ</div>
                    <div className="text-wrapper-27">16:00 AM</div>
                  </div>
                  <div className="text-wrapper-28">someone join the jackpot</div>
                </div>
              </div>
              <div className="frame-40">
                <div className="frame-41">
                  <div className="div-3">
                    <div className="group-3">
                      <div className="overlap-group-4">
                        <img className="star-3" alt="Star" src="/img/star-1-4-2x.png" />
                        <img className="star-4" alt="Star" src="/img/star-2.png" />
                      </div>
                    </div>
                    <div className="overlap-wrapper">
                      <div className="overlap-6">
                        <div className="text-wrapper-31">1</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-43">
                  <div className="frame-15">
                    <div className="text-wrapper-27">ツ HXNRY 1X ツ</div>
                    <div className="text-wrapper-27">16:00 AM</div>
                  </div>
                  <div className="text-wrapper-28">hola</div>
                </div>
              </div>
              <div className="frame-40">
                <div className="frame-41">
                  <div className="div-3">
                    <div className="group-3">
                      <div className="overlap-group-4">
                        <img className="star-3" alt="Star" src="/img/star-1-5.png" />
                        <img className="star-4" alt="Star" src="/img/star-2.png" />
                      </div>
                    </div>
                    <div className="overlap-wrapper">
                      <div className="overlap-group-3">
                        <div className="text-wrapper-26">10</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-43">
                  <div className="frame-15">
                    <div className="text-wrapper-27">ツ HXNRY 1X ツ</div>
                    <div className="text-wrapper-27">16:00 AM</div>
                  </div>
                  <p className="text-wrapper-28">algun maquina que se apañe alguna gemita</p>
                </div>
              </div>
              <div className="frame-40">
                <div className="frame-41">
                  <div className="div-3">
                    <div className="group-3">
                      <div className="overlap-group-4">
                        <img className="star-3" alt="Star" src="/img/star-1-6.png" />
                        <img className="star-4" alt="Star" src="/img/star-2.png" />
                      </div>
                    </div>
                    <div className="overlap-wrapper">
                      <div className="overlap-5">
                        <div className="text-wrapper-29">5</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-43">
                  <div className="frame-15">
                    <div className="text-wrapper-27">ツ HXNRY 1X ツ</div>
                    <div className="text-wrapper-27">16:00 AM</div>
                  </div>
                  <p className="text-wrapper-28">anyone wanna join my 0.69 group battle for the jokes?</p>
                </div>
              </div>
              <div className="frame-40">
                <div className="frame-41">
                  <div className="div-3">
                    <div className="overlap-4">
                      <img className="star" alt="Star" src="/img/star-1-2x.png" />
                      <img className="star-2" alt="Star" src="/img/star-2.png" />
                      <div className="frame-42">
                        <div className="overlap-group-3">
                          <div className="text-wrapper-26">10</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-43">
                  <div className="frame-15">
                    <div className="text-wrapper-27">ツ HXNRY 1X ツ</div>
                    <div className="text-wrapper-27">16:00 AM</div>
                  </div>
                  <div className="text-wrapper-28">admin</div>
                </div>
              </div>
              <div className="frame-40">
                <div className="frame-41">
                  <div className="div-3">
                    <div className="group-3">
                      <div className="overlap-group-4">
                        <img className="star-3" alt="Star" src="/img/star-1-1.png" />
                        <img className="star-4" alt="Star" src="/img/star-2.png" />
                      </div>
                    </div>
                    <div className="overlap-wrapper">
                      <div className="overlap-5">
                        <div className="text-wrapper-29">5</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-43">
                  <div className="frame-15">
                    <div className="text-wrapper-27">ツ HXNRY 1X ツ</div>
                    <div className="text-wrapper-27">16:00 AM</div>
                  </div>
                  <div className="text-wrapper-30">CRAZY TERMINAL battle join</div>
                </div>
              </div>
              <div className="frame-40">
                <div className="frame-41">
                  <div className="div-3">
                    <div className="group-3">
                      <div className="overlap-group-4">
                        <img className="star-3" alt="Star" src="/img/star-1-2-2x.png" />
                        <img className="star-4" alt="Star" src="/img/star-2.png" />
                      </div>
                    </div>
                    <div className="overlap-wrapper">
                      <div className="overlap-6">
                        <div className="text-wrapper-31">1</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-43">
                  <div className="frame-15">
                    <div className="text-wrapper-27">ツ HXNRY 1X ツ</div>
                    <div className="text-wrapper-27">16:00 AM</div>
                  </div>
                  <div className="text-wrapper-28">urob teraz 3way group</div>
                </div>
              </div>
              <div className="frame-40">
                <div className="frame-41">
                  <div className="div-3">
                    <div className="group-3">
                      <div className="overlap-group-4">
                        <img className="star-3" alt="Star" src="/img/star-1-3.png" />
                        <img className="star-4" alt="Star" src="/img/star-2.png" />
                      </div>
                    </div>
                    <div className="overlap-wrapper">
                      <div className="overlap-7">
                        <div className="text-wrapper-29">5</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-43">
                  <div className="frame-15">
                    <div className="text-wrapper-27">ツ HXNRY 1X ツ</div>
                    <div className="text-wrapper-27">16:00 AM</div>
                  </div>
                  <div className="text-wrapper-28">someone join the jackpot</div>
                </div>
              </div>
              <div className="frame-40">
                <div className="frame-41">
                  <div className="div-3">
                    <div className="group-3">
                      <div className="overlap-group-4">
                        <img className="star-3" alt="Star" src="/img/star-1-4-2x.png" />
                        <img className="star-4" alt="Star" src="/img/star-2.png" />
                      </div>
                    </div>
                    <div className="overlap-wrapper">
                      <div className="overlap-6">
                        <div className="text-wrapper-31">1</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-43">
                  <div className="frame-15">
                    <div className="text-wrapper-27">ツ HXNRY 1X ツ</div>
                    <div className="text-wrapper-27">16:00 AM</div>
                  </div>
                  <div className="text-wrapper-28">hola</div>
                </div>
              </div>
              <div className="frame-40">
                <div className="frame-41">
                  <div className="div-3">
                    <div className="group-3">
                      <div className="overlap-group-4">
                        <img className="star-3" alt="Star" src="/img/star-1-5.png" />
                        <img className="star-4" alt="Star" src="/img/star-2.png" />
                      </div>
                    </div>
                    <div className="overlap-wrapper">
                      <div className="overlap-group-3">
                        <div className="text-wrapper-26">10</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-43">
                  <div className="frame-15">
                    <div className="text-wrapper-27">ツ HXNRY 1X ツ</div>
                    <div className="text-wrapper-27">16:00 AM</div>
                  </div>
                  <p className="text-wrapper-28">algun maquina que se apañe alguna gemita</p>
                </div>
              </div>
              <div className="frame-40">
                <div className="frame-41">
                  <div className="div-3">
                    <div className="group-3">
                      <div className="overlap-group-5">
                        <img className="star-5" alt="Star" src="/img/star-1-6.png" />
                        <img className="star-6" alt="Star" src="/img/chevron-down-1.png" />
                      </div>
                    </div>
                    <div className="overlap-wrapper">
                      <div className="overlap-5">
                        <div className="text-wrapper-29">5</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-43">
                  <div className="frame-15">
                    <div className="text-wrapper-27">ツ HXNRY 1X ツ</div>
                    <div className="text-wrapper-27">16:00 AM</div>
                  </div>
                  <p className="text-wrapper-28">anyone wanna join my 0.69 group battle for the jokes?</p>
                </div>
              </div>
            </div>
            <div className="frame-44">
              <div className="frame-45">
                <div className="text-wrapper-32">Your message...</div>
                <img className="smile" alt="Smile" src="/img/smile-13.png" />
                <img className="frame-46" alt="Frame" src="/img/frame-37.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
